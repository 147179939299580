/*!
 * Numizon Global CSS
 * The banknote exploratpr
 * Homepage: https://numizon.com
 *
 * changelog
 * - release 009, date 26.08.2020, current master
 * - release 010 ... - bootstrap 4 included with oscar
 * - release 011M ... - 1st september 20
 * - release 012M ... - 8th september 20
 * - release 3.4.0.alpha1 ... 14th september 20 for Critical CSS support
 *
*/

@charset "utf-8";

/*!
 *
 * Numizon Components
 * Style definition
 *
*/

@import 'variables';

// FRAMEWORK BOOTSTRAP
@import '../node_modules/bootstrap/scss/bootstrap';

//
// VENDORS
//

// Fontawsome
// We are not using it. Use Bootstrap icons instead. from static / frontend / icons
//
// @import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
// @import "../node_modules/@fortawesome/fontawesome-free/scss/regular";
// @import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";

// Spectre
@import 'spectre/variables';
@import 'spectre/steps';

// Oscar
@import 'oscar/scss/styles';
@import 'oscar/override';

/*! purgecss start ignore */

// Menu JS
@import '../node_modules/mmenu-js/src/includes';
@import '../node_modules/mmenu-js/src/variables';
@import '../node_modules/mmenu-js/src/mixins';
@import '../node_modules/mmenu-js/src/mmenu';

/* stylelint-disable */

[class*='mm-menu_pagedim'].mm-menu_opened ~ .mm-wrapper__blocker {
    opacity: 0;
}

.mm-wrapper_opening
    [class*='mm-menu_pagedim'].mm-menu_opened
    ~ .mm-wrapper__blocker {
    opacity: 0.3;
    transition: opacity 0.4s ease 0.4s;
}

/* stylelint-enable */

// Coderedcms
// @import "../staticfiles/coderedcms/css/codered-front.css";
@import '../coderedcms/scss/codered-front';

/*! purgecss end ignore */

// BOOTSTRAP CUSTOM
@import 'bootstrap_custom/badges';
@import 'bootstrap_custom/btn';
@import 'bootstrap_custom/caret';
@import 'bootstrap_custom/container';

@import 'bootstrap_custom/nav';
@import 'bootstrap_custom/table';

// NUMIZON

/*Base*/
@import 'base/iam-colors';
@import 'base/tools';
@import 'base/fonts';

/*Menu*/
@import 'menu/menu-country';

/*Other...*/
@import 'nav/yamm';
@import 'nav/list-country';
@import 'nav/breadcrump';
// @import "nav/megamenu";

@import 'nav/megamenu-banknotemenu';
@import 'nav/mobilemenu';
@import 'nav/navline';
@import 'nav/navsite';

// V5
@import 'v5/navsite5b';
@import 'v5/breadcrump';
@import 'v5/navaction';
@import 'v5/titlebar';
@import 'v5/dropdown';
@import 'v5/tools';
@import 'v5/scroll';
@import 'v5/footer';

@import 'listing/listing_grid';
@import 'listing/listing_banknote';

// @import "nav/autocomplete";
@import 'nav/explorebar';

/*Catalog*/
@import 'catalog/catalog';
@import 'catalog/banknote';
@import 'catalog/catalog-grid';

/*Banknote Detail*/
@import 'banknote/actions';
@import 'banknote/shop';

/*Listing*/
@import 'listing/alertbar';
@import 'listing/hr';
@import 'listing/filters';
@import 'listing/listing_search';
@import 'listing/listing_detail';

// @import "listing/listing_nav";
@import 'listing/3_engine_listing';
@import 'listing/last_listing';

/*CMS*/
@import 'cms/3_cms_articlelist';
@import 'cms/3_cms_pagination';
@import 'cms/3_cms_footer';
@import 'cms/home_slider';
@import 'cms/article';
@import 'cms/picto';
@import 'cms/comment';
@import 'cms/help';

/*Stats*/
@import 'stats/stats_country';

/*User*/
@import 'user/subscribe';
@import 'user/registration';
@import 'user/account';

/*Assets*/

/*@import "assets/btn-social";
@import "assets/animate";
@import "assets/bootstrap-dropdownhover";
@import "assets/pushy";

Interface v4
@import "interface_v4/interface_v4";

END OF NUMIZON CSS GLOBAL*/

/*Admin*/
@import 'admin/3_console_splash';
@import 'admin/3_console_historyindex';
@import 'admin/3_console_card_masonry';
@import 'admin/doc';

/* Wagtail UserBar */
@import 'wagtail/userbar.scss';
