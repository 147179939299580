/* DOCUMENTATION */
.components-example {
  position: relative;
  padding: 1.5rem;
  border: solid #f8f9fa;
  border-width: 0.5rem;
  padding-bottom: 2rem;
}

.components-example.code {
  background-color: #f8f9fa;
  padding-bottom: 1rem;
}

.blabla {
  background-color: rgb(107, 107, 107);
  &.full {
    height: calc(100vh - 100px);
    background-color: rgb(107, 107, 107);
  }
}