/// Image gallery modal/lightbox

.modal-lightbox {
    max-width: 100vw;
    text-align: center;

    img {
        max-height: 90vh;
        max-width: 90vw;
    }
}
