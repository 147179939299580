@import 
	'../../mixins',
	'../../includes',
	'../../variables';


@if ( $mm_include_themes_white ) {
	.mm-menu_theme-white {
		--mm-color-border 				: rgba( 0,0,0, 0.1 );
		--mm-color-button				: rgba( 0,0,0, 0.3 );
		--mm-color-text 				: rgba( 0,0,0, 0.7 );
		--mm-color-text-dimmed			: rgba( 0,0,0, 0.3 );
		--mm-color-background 			: #fff;
		--mm-color-background-highlight	: rgba( 0,0,0, 0.06 );
		--mm-color-background-emphasis	: rgba( 0,0,0, 0.03 );
		--mm-shadow						: 0 0 10px rgba( 0,0,0, 0.2 );
	}
}

@if ( $mm_include_themes_dark ) {
	.mm-menu_theme-dark {
		--mm-color-border 				: rgba( 0,0,0, 0.3 );
		--mm-color-button				: rgba( 255,255,255, 0.4 );
		--mm-color-text 				: rgba( 255,255,255, 0.85 );
		--mm-color-text-dimmed			: rgba( 255,255,255, 0.4 );
		--mm-color-background 			: #333;
		--mm-color-background-highlight	: rgba( 255,255,255, 0.08 );
		--mm-color-background-emphasis	: rgba( 0,0,0, 0.1 );
		--mm-shadow						: 0 0 20px rgba( 0,0,0, 0.5 );
	}
}

@if ( $mm_include_themes_black ) {
	.mm-menu_theme-black {
		--mm-color-border 				: rgba( 255,255,255, 0.25 );
		--mm-color-button				: rgba( 255,255,255, 0.4 );
		--mm-color-text 				: rgba( 255,255,255, 0.75 );
		--mm-color-text-dimmed			: rgba( 255,255,255, 0.4 );
		--mm-color-background 			: #000;
		--mm-color-background-highlight	: rgba( 255,255,255, 0.2 );
		--mm-color-background-emphasis	: rgba( 255,255,255, 0.15 );
		--mm-shadow						: none
	}
}
