@if ($mm_include_counters and $mm_include_iconpanels) {
    [dir='rtl'] .mm-menu_iconpanel .mm-panels {
        > .mm-panel {
            left: 0;
            right: auto;
            transition-property: transform, width;

            &:not(.mm-panel_iconpanel-first):not(.mm-panel_iconpanel-0) {
                border-left: none;
                border-right: 1px solid;
                border-color: inherit;
            }
        }
    }
}
