/*NAVSITE IS NAVIGATION BAR*/

$size-navsite: 56px;
$size-navsite-marginpage: 70px;
$size-navsite-marginpage-md: 110px;
$size-navsite-mobile: 50px;
$size-navsite-marginpage-mobile: 90px;

/*this if navline enabled
$top-navsite: 27px;*/
$top-navsite: 0;

$size-search: 40px;

.navaction-horizontal {  background-color: #fff; height: auto !important; min-height: auto !important; max-height: initial !important; flex-wrap: wrap; }
.navsite5-group-horizontal { height: auto !important; min-height: auto !important; max-height: initial !important; flex-wrap: wrap; }


.scroll-top-fixed {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: #fafafa;
}

.refine-mobile-search { position: fixed; top:0px; left:0; right:0; z-index:1500;}
.box-shadow {     box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.3); }


.scroll-filters-fixed, #fixed-filters {
    // position: fixed;
    top: $size-navsite;
    z-index: 999;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
    animation-name: fadeInDown;
    animation-duration: 100;

    position: -webkit-sticky;
    position: sticky;     
}

[data-topzero="1"] {
    top: 0 !important;
}


.body-top-spacenav {
    padding-top: $size-navsite;
}

.navsite5 {
    @include default-container();
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // width: 100%;
    height: $size-navsite;
    min-height: $size-navsite;
    // margin-top: .25rem;
    // margin-bottom: .25rem;

}

.navsitewide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 56px;
    min-height: 56px;
    width: 100%;
}

.navsite5-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    // max-height: $size-navsite;
    margin-bottom: 0;
    &.grow {
        flex-grow: 2;
        align-items: center;
        justify-content: flex-end;
    }
    &.search {
        position: relative;
        z-index: 100;
    }
    &.right {
        justify-content: flex-end;
        margin-right: 1rem;
    }

    &.self-end {
        justify-self: flex-end !important;
    }
    &.vertical {
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &.vertical-middle {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .navsite-link-medium-box {
        width: 90px;
        height: 100%;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 0.8rem;
        
        color: $navline; 

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    .navsite-link-xxl-box {
        width: 150px;
        height: 100%;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    .navsite-link-small-box {
        width: 50px;
        height: 100%;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        
        color: $navline;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    // label {
    //     display: inline-block !important;
    //     margin-bottom: 0.75rem;
    // }
    .label {
        font-size: 0.8rem;
    }
    .navsite-link-large-box-border {
        width: 100px !important;
        height: 100%;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-right: 1px solid $light2;
        border-left: 1px solid $light2;
        // &:first-child {
        // 	border-left: 1px solid #fff;
        // }
        // &:last-child {
        // 	border-right: 1px solid #fff;
        // }
    }
    .navsite-box-nosize {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        // height: 70px;
        padding: 0.2rem 0.25rem;
        margin: 0;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $navline;
        text-align: center;
    }
    .navsite-box-nomargin {
        width: 140px !important;
        height: 100%;
    }
    .navsite-link {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 100%;
        // height: 70px;
        padding: 0.2rem 0.5rem;
        margin: 0;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $navline;
        text-align: center;
        cursor: pointer;

        &.disabled {
            color: lightgray;
            cursor: not-allowed;
            text-decoration: none;
            img {
                opacity: 0.3;
            }
        }

        &.navsite-link-small {
            width: 50px;
        }
        &.navsite-link-borders {
            border-right: 1px solid $light2;
            border-left: 1px solid $light2;
        }
        &.navsite-link-row-nomargin {
            width: 140px !important;
            flex-direction: row !important;
            border-right: transparent;
            border-left: transparent;
        }

        &.navsite-link-large-nomargin {
            width: 100%;
        }

        &.navsite-link-medium {
            width: 90px;
            border-right: 1px solid $light2;
            &:first-child:not(.navsite5-group-horizontal) {
                border-left: 1px solid $light2;
            }
        }
        &.navsite-link-borderleft {
            border-left: 1px solid $light2;
        }
        &.navsite-link-borderight {
            border-right: 1px solid $light2;
        }
        &.navsite-link-xl {
            width: 120px;
        }
        &.navsite-link-xxl {
            width: 150px;
        }
        &.navsite-link-large {
            width: 100px;
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }
        &.navsite-link-search {
            border-left: 1px solid $light2;
            width: 400px;
            flex-direction: row;
            input {
                margin-right: 0.5rem;
                width: 100%;
            }
        }
        // &.navsite-link-menu {
        //     width: 60px;
        // }
        // img,
        // svg {
        // 	height: 30px;
        // }

        .navsite-picto {
            height: 28px;
        }
        .navsite-picto-hamburger
        {
            height: 21px;
        }
        .navsite-picto-scan {
            height: 28px;
        }
        .navsite-picto-connect {
            height: 28px;
        }

        .badge-cart {
            font-size: 0.75rem;
        }
        .topright {
            position: absolute;
            top: 3px;
            right: 3px;
        }
        &.active {
            background-color: $light2;
        }
        &:not(.disabled):hover {
            text-decoration: none;
        }
        &:not(.disabled):not(.active):not(.nohover):hover {
            color: $navline;
            background-color: $light2;
        }
        &.catalog-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $catalog;
            border-radius: 0;
        }
        &.profile-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $profile;
            border-radius: 0;
        }
        &.listing-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $listing;
            border-radius: 0;
        }
        &.news-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $news;
            border-radius: 0;
        }
        &.archives-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $archives;
            border-radius: 0;
        }
        &.inv-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $inv;
            border-radius: 0;
        }
        &.scan-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $scan;
            border-radius: 0;
        }
    }
}

#id-navsite-logo {
    width: 180px;
    padding-bottom: 10px;
    padding-left: 0px;
}
#id-navsite-picto-logo {
    height: 30px;
}

// OSCAR

.labeltotal {
    font-size: 0.6rem;
    position: absolute;
    top: 0.1rem;
    right: 0.3rem;
}
.basket-mini-item {
    font-size: 0.8rem;
}

.navsite5-group.rightbordernone .navsite-link-medium:last-child {
    border-right: 1px solid #fff;
}
.navsite5-group.leftbordernone .navsite-link-medium:first-child {
    border-left: 1px solid #fff;
}

.resultinfo_count {
    font-size: 0.8rem;
    text-align: left;
    line-height: 1rem;
    width: 180px !important;
    height: 100%;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid $light2;
    border-left: 1px solid $light2;
}

#id-navsite-link-search[aria-expanded='true'] {
    background-color: $light2;
}

#vue-searchgroup .notmounted {
    width: 760px;
    background-color: #eee;
    display: flex;
    padding: 1rem;
    font-size: 1rem;
}
#searchgroup-adv, .searchgroup-bg {
    background-color: $light2;
}
#vue-searchgroup .notmounted h3 {
    font-size: 1rem;
    margin-bottom: 0;
}
.searchgroup-adv-container {
    display: flex;
    background-color: $light2;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    z-index: 900;
    position: relative;
}
.searchgroup-adv-container h1 {
    font-size: 1.2rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;
}
.searchgroup-adv-container .faqlinks {
    padding-top: 1rem;
    font-size: 0.75rem;
    display: flex;
    justify-content: space-between;
    width: 100%;

    a {
        color: #000;
    }
}

#id-navsite5-anonym #id-navsite-link-search {
    border-left: 0px;
}

.border-top-navaction {
    border-top: 1px solid darkgray;
}
.border-bottom-navaction {
    border-top: 1px solid darkgray;
}

@media (max-width: 20px) {
    #id-navsite5-auth #id-navsite-link-scan {
        width: 70px;
    }
    .navsite5-group .navsite-link.navsite-link-row-nomargin,
    .navsite5-group .navsite-box-nomargin {
        width: 80px !important;
    }
    .navsite-link-row-nomargin .label {
        display: none;
    }
    .navsite-link-row-nomargin .navsite-picto {
        width: 28px;
    }
}

@media (max-width: $tablette-breakpoint) {

    .navsite5-group .navsite-link.navsite-link-row-nomargin,
    .navsite5-group .navsite-box-nomargin {
        width: 110px !important;
    }   
}

@media (max-width: $mobile-breakpoint) {
    label[for=favoritecidimg] .label,
    label[for=pidalertimg] .label,
    .navsite-link-row-nomargin .label {
        display: none;
    }    

    .navactionfilters .navsite5-group {
        justify-content: space-between;
        max-width: 100%;
    }

    .navsite5-group .navsite-link.navsite-link-row-nomargin,
    .navsite5-group .navsite-box-nomargin {
        width: 60px !important;
    }       

    .navactionfilters .navsite5-group .navsite-link.navsite-link-medium,
    .navactionfilters .navsite5-group .navsite-link-medium-box {
        width: auto;
    }

    #id-navsite-logo {
        padding-left: 2.5px;
        padding-right: 2.5px;
        width: 100%;
        padding-bottom: 12px;
    }
    #id-navsite-picto-logo {
        height: 25px;
    }    
    
    #id-navsite-hamburger {
        width: 50px !important;
    }
    .navsite5-group .navsite-link .navsite-picto-hamburger
    {
        height: 15px;
    }
}

@media (max-width: $xs-mobile-breakpoint) {
    .navsite5-group .navsite-link.navsite-link-row-nomargin,
    .navsite5-group .navsite-box-nomargin {
        width: 50px !important;
    }       
    #id-navsite-hamburger {
        width: 40px !important;
    }
}


@media (max-width: 2px) {
    #id-navsite5-anonym .navsite5-group .navsite-link.navsite-link-row-nomargin,
    #id-navsite5-anonym .navsite5-group .navsite-box-nomargin {
        // width: 40px !important;
        // border: 1px solid red;
        display: none;
    }
    .navsite5-group .navsite-link.navsite-link-row-nomargin,
    .navsite5-group .navsite-box-nomargin {
        width: 40px !important;
    }
    #id-navsite5-anonym #id-navsite-link-search,
    #id-navsite5-anonym #id-navsite-link-vso {
        display: none;
    }


    // .navsite5-group .navsite-link.navsite-link-search { width: 44px; }
    #id-connect-mobile {
        width: 44px;
        display: flex;
    }
    .navsite-picto,
    .navsite-link-row-nomargin .navsite-picto {
        width: 18px;
    }
    #id-navsite5-auth .navsite-link-medium {
        width: 44px;
    }
    #id-navsite5-auth #id-navsite-link-scan {
        width: 44px;
    }
    #id-navsite5-auth #id-navsite-link-cart {
        width: 44px;
    }

    #id-navsite-logo {
        padding-left: 10px;
        width: 100%;
        padding-bottom: 10px;
    }
    #id-navsite-hamburger {
        width: 40px;
    }
    #id-navsite-picto-logo {
        height: 22px;
    }

    .navsite5-group .btn-sm {
        font-size: 0.8rem !important;
        line-height: 1rem;
    }
    #dropdownMenuButtonLanguage {
        padding: 0.25rem;
    }
    .navsite5 {
        padding: 0 !important;
    }
    .navsite5-group .navsite-box-nosize {
        padding: 0 0.1rem;
    }

    #id-navsite5-auth .navsite-link-medium {
        width: 50px;
    }
    #id-navsite5-auth #id-navsite-link-scan {
        width: 50px;
    }
    #id-navsite5-auth #id-navsite-link-cart {
        width: 50px;
    }
    #id-navsite5-auth #id-navsite-link-search {
        width: 50px;
    }
}
