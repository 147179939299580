@import '../../mixins', '../../includes', '../../variables';

@if ($mm_include_borderstyle_none) {
    .mm-menu_border-none {
        .mm-listitem:after {
            content: none;
        }
    }
}

@if ($mm_include_borderstyle_full) {
    .mm-menu_border-full {
        .mm-listitem:after {
            left: 0 !important;
        }
    }
}
