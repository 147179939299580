/* BANKNOTE */
/* */

.banknote-related { display: flex; flex-wrap: wrap; }

.banknote-related-link { color: $news; display: flex; margin: .5rem; flex-direction: row; }
.banknote-related-link:hover { text-decoration: none; }
.banknote-related-link:hover .banknote-related-image { opacity: .7;}
.banknote-related-image { width: 200px; height: 80px; margin-right: .5rem; overflow: hidden; }
.banknote-related-text { margin:0; padding: 0; }
.banknote-related-text h3 { font-size: 1.1rem !important; margin: 0; margin-bottom: .3rem; }


#banknote-tab {
    font-size: 0.9rem;
}

.banknote-whereiam {
    padding: 1em;
    padding-left: 3em;
}

.banknote-whereiam a {
    color: $dark2;
}

.banknote-whereiam a:hover {
    color: $catalog;
    text-decoration: underline;
}

.banknote-padding {
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.banknote-info {
    width: 100%;
}

.banknote-info-period {
    font-weight: normal;
    padding-bottom: 1rem;
    font-size: 1.5rem;
}

.banknote-info-issuing {
    margin-left: 0.4em;
    margin-right: 0.4em;
}

.banknote-info-issuing a {
    text-decoration: none;
    margin-left: 0.4em;
    color: $dark2;
}

.banknote-info-issuing a:hover {
    text-decoration: underline;
}

.banknote-info-title {
    margin: 0;
    font-size: 2.4rem;
    color: $dark2;
    font-weight: bold;
    margin-left: 0.4em;
}

.banknote-info-pick {
    font-weight: bold;
    font-size: 1.2rem;
    background-color: $white;
    color: $dark2;
    margin: 0 !important;
}

.pick {
    font-size: 2.5rem;
    font-weight: normal;
}

/* VARIANT */
#banknote_variant {
    background-color: $white;
    padding: 0.5em;
    margin-top: 2em;
    border-radius: 5px;
}

.label_variant {
    font-weight: bold;
    color: $dark2;
}

.mini-table th {
    background-color: $dark2;
    border-radius: 0;
    color: $white;
}

.mini-table th,
.mini-table tr,
.mini-table td {
    padding: 0.1rem !important;
    margin: 0.1rem !important;
}

.mini-table th {
    font-weight: normal;
}

.mini-table {
    margin-bottom: 0em;
}

.variant-hightlight {
    font-weight: bold;
}

.pickvariant:hover {
    background-color: $catalog !important;
    color: $white !important;
    cursor: pointer;
}

.pickvariantselected {
    background-color: $catalog !important;
    color: $white !important;
    cursor: pointer;
}
