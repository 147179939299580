// Arrow buttons
@mixin mm_btn_arrow_prev {
	transform: rotate( -45deg );
	left: $mm_listitemIndent + 3;
	right: auto;
}

@mixin mm_btn_arrow_next {
	transform: rotate( 135deg );
	right: $mm_listitemIndent + 3;
	left: auto;
}


//	Misc
@mixin mm_ellipsis() {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@mixin mm_clearfix() {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}
@mixin mm_offcanvas_size(
    $cls: '.mm-menu_offcanvas',
    $width: $mm_menuWidth,
    $minWidth: $mm_menuMinWidth,
    $maxWidth: $mm_menuMaxWidth
) {
    #{$cls} {
        width: percentage($width);
        min-width: $minWidth;
        max-width: $maxWidth;
    }

    .mm-wrapper_opening {
        #{$cls}.mm-menu_opened ~ .mm-slideout {
            transform: translate3d(#{$width * 100}vw, 0, 0);
        }
    }

    //	adjust for min- and max-width
    @media all and (max-width: $minWidth / $width) {
        .mm-wrapper_opening {
            #{$cls}.mm-menu_opened ~ .mm-slideout {
                transform: translate3d($minWidth, 0, 0);
            }
        }
    }

    @media all and (min-width: $maxWidth / $width) {
        .mm-wrapper_opening {
            #{$cls}.mm-menu_opened ~ .mm-slideout {
                transform: translate3d($maxWidth, 0, 0);
            }
        }
    }
}

@mixin mm_columns_size($nr) {
    [class*='mm-menu_columns-'] .mm-panels > .mm-panel_columns-#{$nr} {
        transform: translate3d($nr * 100%, 0, 0);
    }

    .mm-menu_columns-#{$nr} .mm-panels > .mm-panel {
        z-index: $nr;

        @if ($nr > 0) {
            width: ceil(100% / $nr * 100) / 100;
        }
        else {
            width: 100%;
        }

        &:not(.mm-panel_opened):not(.mm-panel_opened-parent) {
            transform: translate3d(($nr + 1) * 100%, 0, 0);
        }
    }

    @include mm_offcanvas_size(
        '.mm-menu_columns-#{$nr}',
        $mm_menuWidth,
        $mm_menuMinWidth,
        $mm_menuMaxWidth * $nr
    );
    @include mm_position_right(
        '.mm-menu_columns-#{$nr}',
        $mm_menuWidth,
        $mm_menuMinWidth,
        $mm_menuMaxWidth * $nr
    );
}

@mixin mm_iconpanel_size($nr) {
    .mm-panel_iconpanel-#{$nr} {
        @if ($mm_IE11Fallbacks) {
            width: calc(100% - #{$mm_iconpanelSize * $nr});
        }

        width: calc(100% - (var(--mm-iconpanel-size) * #{$nr}));
    }
}

@mixin mm_navbar_tabs_borders( $top, $bottom, $last, $first ) {
	.mm-navbars_#{$top} {
		.mm-navbar_tabs:not( :#{$last}-child ) {
			border-#{$bottom}-width: 1px;
			border-#{$bottom}-style: solid;
		}
		.mm-navbar__tab_selected {
			border-#{$bottom}: none;
			margin-#{$bottom}: -1px;
		}
		.mm-navbar_tabs:#{$first}-child .mm-navbar__tab_selected {
			border-#{$top}: none;
		}
	}
}

@mixin mm_effect_listitem_delay( $i ) {
	&:nth-child( #{$i} ) {
		transition-delay: ( $i * 50ms );
	}
}
//	Position right
@mixin mm_position_right(
    $cls: '',
    $width: $mm_menuWidth,
    $minWidth: $mm_menuMinWidth,
    $maxWidth: $mm_menuMaxWidth
) {
    .mm-wrapper_opening {
        #{$cls}.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
            transform: translate3d(#{-$width * 100}vw, 0, 0);
        }
    }

    //	adjust for min- and max-width
    @media all and (max-width: $minWidth / $width) {
        .mm-wrapper_opening {
            #{$cls}.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
                transform: translate3d(-$minWidth, 0, 0);
            }
        }
    }
    @media all and (min-width: $maxWidth / $width) {
        .mm-wrapper_opening {
            #{$cls}.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
                transform: translate3d(-$maxWidth, 0, 0);
            }
        }
    }
}

//	Position top
@mixin mm_position_top(
    $cls: '',
    $height: $mm_menuHeight,
    $minHeight: $mm_menuMinHeight,
    $maxHeight: $mm_menuMaxHeight
) {
    #{$cls}.mm-menu_position-top {
        height: #{$height * 100}vh;
        min-height: $minHeight;
        max-height: $maxHeight;
    }
}

//	Position bottom
@mixin mm_position_bottom(
    $cls: '',
    $height: $mm_menuHeight,
    $minHeight: $mm_menuMinHeight,
    $maxHeight: $mm_menuMaxHeight
) {
    #{$cls}.mm-menu_position-bottom {
        height: #{$height * 100}vh;
        min-height: $minHeight;
        max-height: $maxHeight;
    }
}
