// Use new css optimization
// in articles and home

.cursor-pointer {
    cursor: pointer;
}

.content-section {
    content-visibility: auto;
}

.text-decoration-underline {
    text-decoration: underline;
}

// Used in navsite - not anymore
.noscroll {
    overflow: hidden;
}

.no-bottom-margin {
    margin-bottom: 0;
}

.strong-div {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0.4rem;
}

// password reset form - not used
.fullsize {
    width: 100%;
}

.bold {
    font-weight: bold;
}

// link hover nodecoration
.no-decoration:hover {
    text-decoration: none;
}

// flex tools - stats_country
.flexcol {
    display: flex;
    flex-direction: column;
}

// base - alerts
.no-marginbottom {
    margin-bottom: 0;
}

// article-list
.no-padding {
    padding: 0;
}

// display-mobile
@media (max-width: $mobile-breakpoint) {
    .display-mobile {
        display: block;
    }

    .display-web {
        display: none !important;
    }

    .flex-column-mobile {
        flex-direction: column;
    }

    .nopadding-mobile {
        padding: 0 !important;
    }
}

@media (min-width: $mobile-breakpoint + 1px) {
    .display-mobile {
        display: none !important;
    }

    .display-web {
        display: block;
    }
}

@media (max-width: $tablette-breakpoint) {
    .display-tablette {
        display: block;
    }

    .hide-tablette {
        display: none !important;
    }
}

@media (min-width: $tablette-breakpoint + 1px) {
    .display-tablette {
        display: none !important;
    }

    .hide-tablette {
        display: block;
    }
}

.justify-self-start {
    justify-self: flex-start;
}

.justify-self-end {
    justify-self: flex-end;
}
