.breadcrumb {
    font-size: .9rem !important;
    color: #fff;
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0rem;
    padding: 0.25rem 0rem;
}
.breadcrump-black,
.breadcrump-black a {
    color: darkgray !important;
}
.breadcrump-white,
.breadcrump-white a,
.breadcrump-white .breadcrumb-item + .breadcrumb-item::before {
    color: #fff !important;
}
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    content: '>' !important;
}

.breadcrump-back a {
    font-weight: bold;
    padding-right: 1rem;
}
