/*NAVSITE IS NAVIGATION BAR*/

$size-navsite: 70px;
$size-navsite-marginpage: 70px;
$size-navsite-marginpage-md: 110px;
$size-navsite-mobile: 50px;
$size-navsite-marginpage-mobile: 90px;

/*this if navline enabled
$top-navsite: 27px;*/
$top-navsite: 0;

$size-search: 40px;

.navsite-scroll-container {
    // position: absolute;
    top: $top-navsite;
    z-index: 100;
    width: 100%;
    height: $size-navsite;
    color: #000;
    background-color: #fff;

    &.cat-border {
        border: initial;
    }
    &.lis-border {
        border: initial;
    }
    &.home-border {
        border-bottom: 2px solid $home;
    }
    &.news-border {
        border-bottom: 2px solid $news;
    }
    &.cms-border {
        border-bottom: 2px solid $cms;
    }

    &.scan-border {
        border-bottom: 2px solid $scan;
    }

    &.fixed {
        position: fixed;
        top: 0;
        z-index: 22;
        width: 100%;

        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);

        /*animation-name: slideInDown;
    animation-duration: .4s;*/
        animation-name: fadeInDown;
        animation-duration: 100;
    }
    &.fixed.scan-border {
        box-shadow: 0 4px 10px 0 $scan;
    }
    &.fixed.cms-border {
        box-shadow: 0 4px 10px 0 $cms;
    }
    &.fixed.news-border {
        box-shadow: 0 4px 10px 0 $news;
    }
    &.fixed.home-border {
        box-shadow: 0 4px 10px 0 $home;
    }
    &.fixed.cat-border {
        box-shadow: 0 4px 10px 0 $catalog;
    }
    &.fixed.lis-border {
        box-shadow: 0 4px 10px 0 $listing;
    }
}
.no-navsite-marginpage {
    margin-top: $size-navsite-marginpage;
}

.navsite {
    @include default-container();
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // width: 100%;
    height: $size-navsite;
    min-height: $size-navsite;
    // margin-top: .25rem;
    // margin-bottom: .25rem;

    /*ITEMS*/
    .navsite-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0.2rem;
        overflow: hidden;
        font-size: 1rem;
        &.menu {
            padding: 0 0.85rem;
            font-weight: bold;
            svg {
                width: 20px;
                height: 30px;
            }
        }
        &.logo {
            // max-width: 150px;
            padding: 0;
            padding-bottom: 15px;
            margin-right: 0.5rem;
            svg {
                width: 150px;
            }
        }
    }

    /*GROUP*/
}

.navsite-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    max-height: $size-navsite;
    margin-bottom: 0;
    &.grow {
        flex-grow: 2;
        align-items: center;
        justify-content: flex-end;
        padding-right: 3rem;
        padding-left: 3rem;
    }
    &.search {
        position: relative;
        z-index: 100;
    }
    &.right {
        justify-content: flex-end;
        margin-right: 1rem;
    }

    &.self-end {
        justify-self: flex-end;
    }

    &.vertical-middle {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .navsite-link-medium-box {
        width: 90px;
        height: 100%;
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .navsite-link {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 100%;
        // height: 70px;
        padding: 0.2rem 0.5rem;
        margin: 0;
        font-size: 0.8rem;
        line-height: 1rem;
        color: $navline;
        text-align: center;
        cursor: pointer;

        &.disabled {
            color: lightgray;
            img {
                opacity: 0.3;
            }
        }

        &.navsite-link-small {
            width: 20px;
        }
        &.navsite-link-medium {
            width: 90px;
            border-right: 1px solid $light2;
            &:first-child {
                border-left: 1px solid $light2;
            }
        }
        &.navsite-link-large {
            width: 100px;
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }
        img,
        svg {
            height: 30px;
        }

        .topright {
            position: absolute;
            top: 3px;
            right: 3px;
        }
        &.active {
            background-color: $light2;
        }
        &:not(.disabled):not(.active):hover {
            color: $navline;
            text-decoration: none;
            background-color: $light2;
            // border-radius: 50px;
        }
        &.catalog-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $catalog !important;
            border-radius: 0;
        }
        &.profile-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $profile !important;
            border-radius: 0;
        }
        &.listing-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $listing !important;
            border-radius: 0;
        }
        &.news-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $news !important;
            border-radius: 0;
        }
        &.archives-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $archives !important;
            border-radius: 0;
        }
        &.scan-active {
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: $scan !important;
            border-radius: 0;
        }
    }
}

#search {
    width: 100%;
    max-width: 800px;
    height: $size-search;
    max-height: $size-search;

    justify-self: flex-start;
    justify-items: flex-start;
}

// SCREEN RESPONSIVE : REDUCE PADDING
//

@media (max-width: 1199px) {
    .navsite {
        flex-wrap: wrap;
        .navsite-group {
            // flex-grow: 2;
            justify-content: space-around;
            &.grow {
                justify-content: space-around;
                padding-right: 0.75rem;
                padding-left: 0.75rem;
            }
            &.right {
                margin-right: 0;
            }
            .navsite-link {
                margin: 0 0.1rem;
            }
            .navsite-link:first-child {
                margin-left: 1rem;
            }
            .navsite-link:last-child {
                margin-right: 1rem;
            }
        }

        .navsite-item {
            &.logo {
                margin-right: 0.1rem;
            }
        }
    }
}

// SCREEN RESPONSIVE : SPLIT SEARCHBAR
//

@media (max-width: 1024px) {
    #search {
        max-width: 600px;
    }
    .mobile-navsite-break {
        flex-basis: 100%;
        width: 0;
    }
    .navsite .navsite-group {
        align-items: center;
    }
    .navsite .navsite-group.grow.search {
        order: 6;
        height: 38px;
        display: flex;
        align-items: center;
    }
    .navsite .navsite-group.grow.search.lis {
        background-color: $listing;
    }
    .navsite .navsite-group.grow.search.cat {
        background-color: $catalog;
    }
    .navsite-scroll-container {
        height: $size-navsite-marginpage-md;
        min-height: $size-navsite-marginpage-md;
        margin-top: 0;
        margin-right: 0;
    }

    .navsite-marginpage {
        margin-top: $size-navsite-marginpage-md;
    }
}

.navsite-link-large {
    margin-left: 1rem;
}
@media (max-width: 768px) {
    .navsite {
        padding-right: 0.2rem;
        padding-left: 0.2rem;
    }
    .navsite-link-large {
        margin-left: 0;
    }
}

// SCREEN RESPONSIVE : REDUCE LOGO
//

@media (max-width: 650px) {
    #search {
        max-width: 100%;
    }
    .navsite {
        height: $size-navsite-mobile;
        min-height: $size-navsite-mobile;
        margin-top: 0;
        margin-bottom: 0;
        .navsite-item {
            &.menu {
                padding: 0 0.3rem;
            }
            &.logo {
                padding-bottom: 10px;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
                svg {
                    width: 90px;
                }
            }
        }
        .navsite-group {
            flex-grow: 1;
            //height: 45px;
            .navsite-link {
                width: 43px;
                height: 40px;
                padding: 0.3rem 0rem;
                overflow: hidden;
                margin: 0 0.1rem;

                .label {
                    display: none;
                }
                img {
                    height: 30px;
                }
            }
            .navsite-link:first-child {
                margin-left: 0.1rem;
            }
            .navsite-link:last-child {
                margin-right: 0.1rem;
            }
        }
    }
    .navsite-scroll-container {
        height: $size-navsite-marginpage-mobile;
        min-height: $size-navsite-marginpage-mobile;
        margin-top: 0;
        margin-right: 0;
    }

    .navsite-marginpage {
        margin-top: $size-navsite-marginpage-mobile;
    }
}

@media (max-width: 340px) {
    .navsite .navsite-item.logo svg {
        width: 100px;
    }
}

// OSCAR

.labeltotal {
    font-size: 0.6rem;
    position: absolute;
    top: 0.1rem;
    right: 0.3rem;
}
.basket-mini-item {
    font-size: 0.8rem;
}
