@import '../../mixins', '../../includes', '../../variables';

@if ($mm_include_shadows_page) {
    .mm-menu_shadow-page {
        &:after {
            @if ($mm_IE11Fallbacks) {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            }

            content: '';
            display: block;
            width: 20px;
            height: 120%;
            position: absolute;
            left: 100%;
            top: -10%;
            z-index: 100;
            clip-path: polygon(-20px 0, 0 0, 0 100%, -20px 100%);

            box-shadow: var(--mm-shadow);
        }

        @if ($mm_include_positioning_right) {
            &.mm-menu_position-right:after {
                left: auto;
                right: 100%;
                clip-path: polygon(20px 0, 40px 0, 40px 100%, 20px 100%);
            }
        }

        @if ($mm_include_positioning_front) {
            &.mm-menu_position-front:after {
                content: none;
                display: none;
            }
        }
    }
}

@if ($mm_include_shadows_menu) {
    .mm-menu_shadow-menu {
        @if ($mm_IE11Fallbacks) {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }

        box-shadow: var(--mm-shadow);
    }
}

@if ($mm_include_shadows_panels) {
    .mm-menu_shadow-panels .mm-panels > .mm-panel {
        @if ($mm_IE11Fallbacks) {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }

        box-shadow: var(--mm-shadow);
    }
}
