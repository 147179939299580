

/* PLEASE SUBSCRIBE */
.please_subscribe {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding: 1rem;
}

.please_subscribe_title {
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  padding-top: .5rem;
}

.please_subscribe_title span {
  color: #546e7a;
  font-weight: bold;
  display: block;
}

.please_subscribe_title strong {
  color: #546e7a;
  font-weight: bold;
}

.please_subscribe_action {
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
}

.please_subscribe_action span {
  font-size: 1.6rem;
  display: block;
}

.please_subscribe_link {
  color: #546e7a;
  font-weight: bold;
  text-decoration: underline;
}

.please_subscribe_link:hover {
  opacity: 0.7;
}

.annonces_subscribe {
  padding: 1rem;
}
