.explore {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  @include default-container();


  .btn-explore {
    margin-right: .5rem;
    margin-left: .5rem;
    font-size: 1.1rem;
    text-align: left;
  }

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    .primary {
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }
    .btn-explore {
      flex-grow: 2;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      font-size: 1rem;

    }
  }

}


.explore-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: .5rem;
  margin-left: .5rem;
  font-size: .875rem;

}

.explore-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 100%;
  padding: 1rem;
  font-weight: bold;
}

.explore-picto {
  height: 26px;
}


#explore-close,
#i-level2-close,
#i-level3-close {
  display: none;
}

.container-explorebar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;

  margin: auto;
}

.explore-icon-xl {
  padding-bottom: 5px;
  padding-left: 5px;
}

.explore-icon {
  width: 26px;
}

@media screen and (min-width: 1300px) {
  .container-explorebar,
  .container-page {
    width: 1600px;
  }
}

@media screen and (max-width: $tablette-breakpoint) {
  .container-explorebar {
    flex-direction: column;
  }

  .explore {
    height: auto;
  }
}

.not-visible {
  border: 3px solid white !important;
}

.not-visible-area {
  border-bottom: 5px solid $catalog;
  background-color: #fafafa;
  padding: 2rem;
}

// .not-visible-area::before {
//   display: inline-block;
//   padding-right: 1rem;
//   padding-left: 1rem;
//   color: #fff;
//   content: "CATALOG AREA NOT VISIBLE";
//   background-color: #e95420;
// }

.searchnav {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.searchnavall {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #fff;
  background-color: #4e1135;
}

.searchnav-bar {
  padding-right: 3rem;
  padding-left: 3rem;
}

.searchnav-bar-visuel {
  display: flex;
  justify-content: row;
}

.explore-level {
  display: block;
  font-size: 1.1rem;
}

.explore-level .badge {
  padding: 15px;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: normal;
}

.explore-level3 {
  text-align: center;
}

.explore-level3-list {
  display: inline-block;
  text-align: left;
}

.explore-level3-visuel {
  display: inline-block;
  width: 110px;
  text-align: left;
}

.explore-level3 .badge {
  padding: 0 30px 0 0;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: normal;
}

.explore-level .badge-xl {
  font-size: 1.5rem;
}

.explore-help {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: .9rem;
  color: #fafafa;
  text-transform: uppercase;
}

.explore-help:hover {
  color: #fafafa;
}

.xsearchbar-image {
  position: absolute;
  top: 0;
  right: 0;
}

.explore-level2-visuel {
  padding-right: 2rem;
}

.explore-level2-visuel img {
  border-radius: 50px;
  opacity: .8;
}

