// Badges
//

@each $color, $value in $iam-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

.badge-right {
  float: right;
  font-weight: normal;
}

.badge {
  font-weight: normal !important;
}
