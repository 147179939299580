// Resetting margins to the bottom only
// p,
// form,
// .control-group,
// .table,
.page-header,
.sub-header {
    margin: 0 0 $base-margin-bottom;
}

ul,
ol {
    margin-bottom: $base-margin-bottom;
}

.page-header,
.sub-header,
.table {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    form {
        margin-bottom: 0;
    }
}

.h1,
.h2,
.h3 {
    margin-bottom: 0;
}

// Page / sub headers consistant
.sub-header {
    padding-bottom: ($base-margin-bottom / 2) - 1;
    border-bottom: 1px solid $gray-200;
}

.page-header {
    border-bottom: 1px solid $gray-200;
    padding-bottom: 0.625rem;

    h1,
    h2 {
        font-size: $font-size-base * 2.14;
        line-height: 40px;
    }
}

// Sub header a little lighter
.sub-header {
    h2,
    h3,
    h4 {
        font-size: $font-size-base * 1.6;
        line-height: $line-height-base * 1.5;
        font-weight: normal;
        color: $gray-800;
    }
}
