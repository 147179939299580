@import '../../mixins', '../../includes', '../../variables';

$mm_module: '.mm-divider';

#{$mm_module} {
    @if ($mm_IE11Fallbacks) {
        position: relative;
        min-height: $mm_lineHeight;
        padding: (($mm_listitemSize * 0.65) - $mm_lineHeight) * 0.5;
        background: $mm_backgroundColor;

        &:before {
            background: $mm_highlightedBackgroundColor;
        }
    }

    @include mm_ellipsis;

    @supports (position: sticky) {
        position: sticky;
        z-index: 2;
        top: 0;

        .mm-navbar_sticky:not(.mm-hidden) ~ .mm-listview & {
            top: var(--mm-navbar-size);
        }
    }

    min-height: var(--mm-line-height);
    padding: calc(
        ((var(--mm-listitem-size) * 0.65) - var(--mm-line-height)) * 0.5
    );
    padding-right: $mm_padding;
    padding-left: $mm_listitemIndent;

    font-size: 75%;
    text-transform: uppercase;
    background: var(--mm-color-background);

    opacity: 1;
    transition: opacity $mm_transitionDuration $mm_transitionFunction;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: var(--mm-color-background-highlight);
    }
}
