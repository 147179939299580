.dataline {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;
}

.listdetail-info .label {
    width: 150px;
}

.listdetail-info .data-rarity, .listdetail-info .data-cotation {
    display: inline-flex;
    // align-items: baseline;
}

.listdetail-info .data-rarity div:first-child,
.listdetail-info .data-cotation div:first-child {
	width: 70px;
}


.listdetail-info .data-rarity div:last-child,
.listdetail-info .data-cotation div:last-child {
    margin-left: 0.5rem;
    font-size: 0.9rem;
	color: darkgray;
	padding-top: .2rem;
}

.rr {
    height: 11px;
    color: $listing;
    fill: $listing;
    filter: grayscale(0) !important;
}

.c {
    height: 10px;
    padding-left: 2px;
}

@media (max-width: $mobile-breakpoint) {
    .dataline {
        flex-direction: column;
        padding-left: 1rem;
    }

    .listdetail-info .label {
        width: 120px;
    }
}

.listing-item {
    height: 100%;
    min-height: 100vh;
    margin-bottom: 2rem;
    color: #000;
    background-color: $listing-item;
}

.minheight1 {
    min-height: 1rem;
}

.listingdetail-viewarea {
    display: flex;
    flex-direction: row;
}

.listingdetail-gallery {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;

    figcaption {
        display: none;
    }

    figure {
        margin: 0;
    }

    @media (max-width: $mobile-breakpoint) {
        margin-bottom: 1rem;
    }
}

.detail-title {
    font-size: 1.4rem !important;

    span {
        font-size: 1.3rem;
        font-weight: bold;
    }
}

.listing-clickpartner {
    margin-top: 1rem !important;
}

.listdetail-shop {
    // padding: 0 0.5rem !important;

    .listingend {
        font-weight: bold;
        line-height: 1.2rem;
    }

    .label {
        display: block;
        color: darkgray;
    }

    .partnerlogo {
        float: left;
        height: 10px;
    }

    .qualityprice {
        display: flex;
        flex-direction: column;

        // justify-content: space-between;
        // height: 100%;

        line-height: 0.8em;
        text-align: center;

        .buytype {
            padding-bottom: 1rem;
            font-weight: normal;
        }

        .price,
        .quality {
            display: inline-block;
            padding: 0.6rem;
            font-size: 2em;
            font-weight: bold;
            line-height: 0.8em;

            text-align: center;
        }

        .quality {
            text-align: center;
        }
    }

    @media (max-width: $mobile-breakpoint) {
        padding: 0 0rem !important;

        .qualityprice {
            padding: 0;
            font-size: 1rem;

            .buytype,
            .price,
            .quality {
                display: inline-block;
                padding: 0rem;
                margin-bottom: 0;

                line-height: 0.8em;

                text-align: center;
            }
        }
    }
}

.detail-card {
    margin-bottom: 0.5rem;

    &.detail-card-border {
        background-color: #fff;
        border: 2px solid $listing;
        border-radius: 10px;
    }

    &.bg-numizon {
        color: #fff;
        background-color: $navline;
        border: 2px solid $navline;
        border-radius: 5px;
    }

    &.bg-white {
        background-color: #fff;

        border-radius: 10px;
    }

    &.bg-dark {
        color: #fff;
        background-color: $navline;
        border: 2px solid $navline;
        border-radius: 5px;
    }

    &.no-borderbottom {
        border-bottom: initial;
    }

    .title {
        padding: 5px;
        color: #fff;
        border-radius: 10px 10px 0 0;
        // background-color: $listing;
        // border-radius: 5px;
    }

    .data {
        padding: 1em;

        @media (max-width: $mobile-breakpoint) {
            &.smallpadding {
                padding: 0.3rem;
            }
        }
    }

    .group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        div {
            margin-right: 0.5rem;
        }
    }

    .bottom {
        padding: 1em;
        font-weight: bold;
        color: $listing;
        border-top: 1px solid $listing;
    }

    .label {
        display: block;
        color: darkgray;
    }

    @media (max-width: $mobile-breakpoint) {
        .labelmobile {
            display: block;
        }
    }
}

.viewbanknote {
    display: flex;
    flex-direction: row;
}

.actiontop {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid #ffeee0;
}

.closemodal {
    display: flex;
    flex-grow: 2;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    padding-left: 1em;
    font-size: 1.1em;
    line-height: 1.1em;
    color: #ffeee0;
    cursor: pointer;
    background-color: #8d1e5f;
}

.memebillet {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 500px;
    height: 70px;
    padding-right: 1em;
    font-size: 1.1em;
    line-height: 1.1em;
    color: #ffeee0;
    cursor: pointer;
    background-color: #8d1e5f;
    border-left: 1px solid #fafafa;
}

.memebillet:link,
.memebillet:active,
.memebillet:visited,
.memebillet:hover {
    color: #ffeee0 !important;
    text-decoration: none;
}

.closemodal:hover,
.memebillet:hover {
    box-shadow: 2px 2px 15px #ccc;
}

.img-rectoverso {
    flex-grow: 2;
    width: calc(100% - 500px);
    box-shadow: 2px 2px 15px #ccc;
}

.detail {
    width: 500px;
    padding-right: 2em;
    padding-left: 2em;
}

.detail-action {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1em;
    font-size: 20px;
    font-weight: bold;
}

.detail-button {
    display: inline-block;
    padding: 1rem;
    margin-right: 1em;
    color: #8d1e5f;
    background-color: #fff;
    border-radius: 5px;
}

.detail-buttonaction {
    display: inline-block;
    padding: 1rem;
    margin-right: 1em;
    color: #fff !important;
    text-decoration: none !important;
    background-color: #8d1e5f;
    border: 2px solid transparent;
    border-radius: 5px;
}

.detail-buttonaction:active,
.detail-buttonaction:visited {
    color: #fff;
    text-decoration: none !important;
}

.detail-buttonaction:hover {
    color: #fff !important;
    border: 2px solid #fff;
    box-shadow: 2px 2px 15px #ccc;
}

.detail-seller-data {
    font-size: 0.9em;
}

.refnumizon {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.9em;
}

@media (min-width: $mobile-breakpoint+1) and (max-width: $tablette-breakpoint) {
    .detail-action {
        font-size: 16px;
    }

    .closemodal {
        flex-grow: 1;
        width: 50%;
        padding-left: 0.5em;
    }

    .memebillet {
        width: 50%;
        padding-right: 0.5em;
    }

    .viewbanknote {
        display: flex;
        flex-direction: row;
    }

    .img-rectoverso,
    .detail {
        width: 50%;
    }

    .detail {
        padding-right: 1em;
        padding-bottom: 1em;
        padding-left: 1em;
        font-size: 0.9em;
    }
}

@media (max-width: $mobile-breakpoint) {
    .detail-action {
        font-size: 16px;
    }

    .closemodal {
        flex-grow: 1;
        width: 50%;
        padding-left: 0.5em;
    }

    .memebillet {
        width: 50%;
        padding-right: 0.5em;
    }

    .viewbanknote {
        display: flex;
        flex-direction: column;
    }

    .img-rectoverso,
    .detail {
        width: 100%;
    }

    .detail {
        padding-right: 1em;
        padding-bottom: 1em;
        padding-left: 1em;
    }
}

/*LISTING*/

/**/

#listing_all {
    padding: 0.5rem;
}

@media (min-width: $tablette-breakpoint) {
    #listing_all {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}

.listing-table {
    xborder: 3px solid red;
}

.container-listing-small-img {
    width: 120px;
    max-height: 60px;
    xoverflow: hidden;
}

.listing-small-img {
    width: 120px;
}

.listing-info-title {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2rem;
}

.fontebay {
    padding-left: 1rem;
    font-size: 2rem;
}

.fontcat {
    font-size: 1.5rem;
}

.listing-container {
    background-color: #fff;
    xmargin: 3rem;
}

/*DETAIL*/

/**/

.actiontop {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid #ffeee0;
}

.closemodal {
    display: flex;
    flex-grow: 2;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    padding-left: 1em;
    font-size: 1.1em;
    line-height: 1.1em;
    color: #ffeee0;
    cursor: pointer;
    background-color: #8d1e5f;
}

.memebillet {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 70px;
    padding-right: 1em;
    font-size: 1.1em;
    line-height: 1.1em;
    //width: 500px;
    color: #ffeee0;
    cursor: pointer;
    background-color: #8d1e5f;
    border-left: 1px solid #fafafa;
}

.memebillet:link,
.memebillet:active,
.memebillet:visited,
.memebillet:hover {
    color: #ffeee0 !important;
    text-decoration: none;
}

.closemodal:hover,
.memebillet:hover {
    box-shadow: 2px 2px 15px #ccc;
}

.viewbanknote {
    display: flex;
    flex-direction: row;
}

.detail {
    padding-right: 2em;
    padding-left: 2em;
    //width: 500px;
}

.detail-action {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1em;
    font-size: 20px;
    font-weight: bold;
}

.detail-button {
    display: inline-block;
    padding: 1rem;
    margin-right: 1em;
    color: #8d1e5f;
    background-color: #fff;
    border-radius: 5px;
}

.detail-buttonaction {
    display: inline-block;
    padding: 1rem;
    margin-right: 1em;
    color: #fff !important;
    text-decoration: none !important;
    background-color: #8d1e5f;
    border: 2px solid transparent;
    border-radius: 5px;
}

.detail-buttonaction:active,
.detail-buttonaction:visited {
    color: #fff;
    text-decoration: none !important;
}

.detail-buttonaction:hover {
    color: #fff !important;
    border: 2px solid #fff;
    box-shadow: 2px 2px 15px #ccc;
}

.refnumizon {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.9em;
}

.detail-color2 {
    border: 2px solid #8d5c79;
}

.detail-color2 {
    background-color: #8d5c79;
}

@media (min-width: $mobile-breakpoint+1) and (max-width: $tablette-breakpoint) {
    .detail-action {
        font-size: 16px;
    }

    .closemodal {
        flex-grow: 1;
        width: 50%;
        padding-left: 0.5em;
    }

    .memebillet {
        width: 50%;
        padding-right: 0.5em;
    }

    .viewbanknote {
        display: flex;
        flex-direction: row;
    }

    .img-rectoverso,
    .detail {
        width: 50%;
    }

    .detail {
        padding-right: 1em;
        padding-bottom: 1em;
        padding-left: 1em;
        font-size: 0.9em;
    }
}

@media (max-width: $mobile-breakpoint) {
    .detail-action {
        font-size: 16px;
    }

    .closemodal {
        flex-grow: 1;
        width: 50%;
        padding-left: 0.5em;
    }

    .memebillet {
        width: 50%;
        padding-right: 0.5em;
    }

    .viewbanknote {
        display: flex;
        flex-direction: column;
    }

    .img-rectoverso,
    .detail {
        width: 100%;
    }

    .detail {
        padding-right: 1em;
        padding-bottom: 1em;
        padding-left: 1em;
    }
}