$mm_module: '.mm-btn';

@if ( $mm_include_rtl ) {
	[dir="rtl"] {
		#{$mm_module} {
			&_next:after {
				@include mm_btn_arrow_prev;
			}
		

			&_prev:before {
				@include mm_btn_arrow_next;
			}

			&_close {
				&:before,
				&:after {
					right: auto;
				}

				&:before {
					left: $mm_listitemIndent + 5;
				}

				&:after {
					left: $mm_listitemIndent - 2;
				}
			}
		}
	}
}
