.banknote-h1 {
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: bold;
}

.banknote-shop-cgv {
    color: #000;
    font-size: .8rem;
}

.banknote-shop-cgv a {
    color: #000;
}

.listingvendor {
    color: #000;
    font-size: 1rem;
    font-weight: bold;
}
.listinglabel,
.listingid {
    color: darkgray;
    font-size: .9rem;
}

.banknote-header {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.banknote-price {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: .5rem;
    display: flex;
    line-height: 1.3rem;
    font-size: .9rem;
}

.banknote-price .qualityprice {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banknote-price .buytype {
    padding-bottom: 0px !important;
    font-size: .9rem;
}

.banknote-price .price {
    padding: 0 !important;
}

.banknote-price .price {
    margin-right: .5rem;
}

.banknote-price .buytype {
    margin-top: .5rem;
}

.banknote-price-col {
    flex-grow: 2;
}

.listingBasket {
    text-align: center;
    padding-bottom: 1rem;
    padding-top: .5rem;
}

.btn-buy {
    padding: .7rem 1.4rem;
}

@media (max-width: $mobile_breakpoint) {
    .banknote-h1 {
        font-size: 1.1rem;
    }

    .banknote-price .qualityprice {
        margin-top: .5rem;
    }


}

@media (max-width: $tablette_breakpoint) {
    .banknote-price {
        flex-direction: column;
    }

    .salestime {
        display: flex;
        justify-content: space-between;
    }

    .salestime:first-child div {
        order: 3;
    }
}