
/* responsive breakpoints based on bootstrap */
$xs-mobile-breakpoint: 374px;
$mobile-breakpoint: 576px;
$tablette-breakpoint: 992px;

/*Colors*/
$listing: #e95420;
$listing-dark: darken($listing, 9%);
$listing-area: lighten(#dee2e6, 3%) !default;
$listing-item: lighten($listing-area, 6%) !default;
$listing-itemdark: darken($listing-area, 6%) !default;
$listing-active: #f8f9fa !default;
$catalog: #6f1753 !default;
$catalog-dark: darken($catalog, 9%);
$catalog-content: lighten(#ffd7aa, 10%);
$archives: #c63250 !default;
$archives-dark: darken($archives, 9%);
$cotation: #327d48 !default;
$scan: #1c899a !default;
$news: #789683 !default;
$news-dark: darken($news, 9%);
$inv: #626262 !default;
$subscribe: #ffbf00;
$subscribe-light: lighten($subscribe, 40%);
// $subscribe-dark: darken($subscribe, 9%);
$navline: #626262 !default;
$home: $catalog !default;
$home-content: #fafafa !default;
$cms: #6f1753 !default;
$profile: #327d48 !default;
$profile-dark: darken($profile, 9%);
$dark2: #212529 !default;
$menu-area: #dee2e6 !default;
$menu-item: lighten($menu-area, 3%) !default;
$menu-active: #f8f9fa !default;
$light2: #eeeaea !default;
$light3: #ffd7aa !default;
$white: #fff !default;
$black: #000 !default;
$filters: #faf5f5 !default;

$catalog-hover: darken($catalog, 40%) !default;
$image-border: $navline !default;

$profile-light: #ecfddf;
$listing-active: #f8f9fa;
$content-light: #fafafa;
$content-dark: #e7eaed;
$action-highlight: #eeeaea;

// fusv-disable
$body-bg: #fafafa;

$iam-colors: () !default;
$iam-colors: map-merge(
    (
        'lis': $listing,
        'lisdark': $listing-dark,
        'cat': $catalog,
        'catdark': $catalog-dark,
        'inv': $inv,
        'scan': $scan,
        'cms': $cms,
        'news': $news,
        'home': $home,
        'cotation': $cotation,
        'archives': $archives,
        'archivesdark': $archives-dark,
        'profile': $profile,
        'profiledark': $profile-dark,
        'subscribe': $subscribe,
        'navline': $navline,
    ),
    $iam-colors
);

$secondary-colors: () !default;
$secondary-colors: map-merge(
    (
        'profile-light': $profile-light,
        'listing-area': $listing-area,
        'listing-active': $listing-active,
        'content-light': $content-light,
        'content-dark': $content-dark,
        'action-highlight': $action-highlight,
    ),
    $secondary-colors
);

:root {
    @each $color, $value in $iam-colors {
        --#{$color}: #{$value};
    }

    @each $color, $value in $secondary-colors {
        --#{$color}: #{$value};
    }
}

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;

    font-display: swap;
    src: url('../fonts/Nunito-ExtraBold.woff2') format('woff2'),
        url('../fonts/Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito-ExtraBold.woff') format('woff'),
        url('../fonts/Nunito-ExtraBold.ttf') format('truetype'),
        url('../fonts/Nunito-ExtraBold.svg#Nunito-ExtraBold') format('svg');
}

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;

    font-display: swap;
    src: url('../fonts/Nunito-Regular.woff2') format('woff2'),
        url('../fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito-Regular.woff') format('woff'),
        url('../fonts/Nunito-Regular.ttf') format('truetype'),
        url('../fonts/Nunito-Regular.svg#Nunito-Regular') format('svg');
}

// $font-family-sans-serif: "Nunito Sans", "Segoe UI", "Roboto", "Helvetica Neue",
//   "Arial", sans-serif !default;

$font-family-sans-serif: 'Nunito Sans', 'Arial', sans-serif;

$font-stack: $font-family-sans-serif;
$font-stack-serif: $font-stack;

$font-size-base: 1rem !default;
$fontsize-xxs: 0.6rem !default;
$fontsize-xs: 0.875rem !default;
$fontsize-sm: 1rem !default;
$btn-fontsize: 0.9rem;
$btn-fontsize-mobile: 0.9rem;
