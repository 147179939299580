.picto-h2 {
  margin-bottom: 0px;
  font-size: 1.2rem;
  font-weight: bold;

  @media (max-width: $mobile-breakpoint) {
    font-size: 1.1rem;
  }
}

.h2-title-no-bold {
	margin-bottom: 0px;
  font-size: 1.2rem;
}

.detail-catalog-wrapper {
  margin-bottom: 4rem;
}

.picto-link {
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	width: 100%; 
	height: 60px;	
}
.picto-link:hover {
  text-decoration: none;
}

.picto-margin {
  margin: 1rem .5rem !important;
}


.pictobar {
  margin: 0;
  color: #000;
  background-color: #fff;
  border: 2px solid $listing-area;
  border-radius: 5px;
  // box-shadow: $lis-shadow; // !important;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  margin-bottom: .5rem;

  .pictobar-topmargin {
    margin-top: 3rem;
  }

  &:hover {
    z-index: 10;
    color: #fff;
    cursor: pointer;
    background-color: $listing-active;

    // box-shadow: $lis-shadow; // !important;

  }

  &.pictobar-listing:hover {
    border: 2px solid $listing;
    border-radius: 10px;
	background-color: $listing;
	.picto-h2 { color: #fff !important; }	
  }

  &.pictobar-catalog:hover {
    border: 2px solid $catalog;
    border-radius: 10px;
	background-color: $catalog;
	.picto-h2 { color: #fff !important; }	
  }

  &.pictobar-news:hover {
    border: 2px solid $news;
    border-radius: 10px;
	background-color: $news;
	.picto-h2 { color: #fff !important; }
  }

  &.pictobar-scan:hover {
    border: 2px solid $scan;
    border-radius: 10px;
	background-color: $scan;
	.picto-h2 { color: #fff !important; }	
	
  }

  &.pictobar-cms:hover {
    border-radius: 10px;
    border: 2px solid $cms;
	background-color: $cms;
	.picto-h2 { color: #fff !important; }
  }

  &.pictobar-profile:hover {
    border: 2px solid $profile;
    border-radius: 10px;
	background-color: $profile;
	.picto-h2 { color: #fff !important; }	
  }

  &.pictobar-navline:hover {
    border: 2px solid $navline;
    border-radius: 10px;
	background-color: $navline;
	.picto-h2 { color: #fff !important; }	
  }
}

.pictobar-nohover {
  margin: 0;
  color: #000;
  background-color: #fff;
  // border: 2px solid transparent;
  // box-shadow: $lis-shadow; // !important;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

// .pictobar:hover .picto-square {
//   border-radius: 0;
// }

.picto-navsite {
	position: relative;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 100%;
	padding: 0.2rem 0.5rem;
	margin: 0;
	font-size: 0.8rem;
	line-height: 1rem;
	text-align: center;

	&.navsite-link-small {
		width: 20px;
	}
	&.navsite-link-large {
		width: 100px;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
	img,
	svg {
		height: 30px;
	}
}


.picto-square {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;

  font-size: .8rem;
  color: #fff;
  border-radius: 5px;

  // &:hover {
  //   border-radius: 0;
  // }

  &.picto-wide {
	  width: 100%;
	  margin-right: .2rem;
	  margin-left: .2rem;
	  &:hover { opacity: 0.95; color: #fff; text-decoration: none; }
  }

  &.picto-square-listing {
    background-color: $listing;
  }

  &.picto-square-news {
    background-color: $news;
  }

  &.picto-square-catalog {
    background-color: $catalog;
  }

  &.picto-square-scan {
    background-color: $scan;
  }

  &.picto-square-profile {
    background-color: $profile;
  }

  &.picto-square-navline {
    background-color: $navline;
    color: #fff;
    fill: #fff;
  }

  &.picto-square-cms {
    background-color: $cms;
  }

  &.picto-square-archives {
    background-color: $archives;
  }

  &.picto-square-cotation {
    background-color: $cotation;
  }

  img,
  svg {
    width: 32px;
    height: auto;
  }

}

.picto-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;

}

.picto-margintop {
  margin-top: 6rem !important;
}


.picto-subscribe {
  // background-image: url(https://numizon.s3.amazonaws.com/media/account/subscribe.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $catalog;
}

.picto-banner-contain {
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  background-color: $catalog;
}