@import 
	'../../mixins',
	'../../includes',
	'../../variables';


@if ( $mm_include_pagedim_default or $mm_include_pagedim_black or $mm_include_pagedim_white ) {
	[class*="mm-menu_pagedim"].mm-menu_opened {
		~ .mm-wrapper__blocker {
			opacity: 0;
		}
	}

	.mm-wrapper_opening [class*="mm-menu_pagedim"].mm-menu_opened {
		~ .mm-wrapper__blocker {
			opacity: $mm_pagedimOpacity;
			transition: opacity $mm_transitionDuration $mm_transitionFunction $mm_pagedimDelay;
		}
	}

	.mm-menu_opened {
		@if ( $mm_include_pagedim_default ) {
			&.mm-menu_pagedim ~ .mm-wrapper__blocker {
				background: inherit;
			}
		}

		@if ( $mm_include_pagedim_black ) {
			&.mm-menu_pagedim-black ~ .mm-wrapper__blocker {
				background: #000;
			}
		}

		@if ( $mm_include_pagedim_white ) {
			&.mm-menu_pagedim-white ~ .mm-wrapper__blocker {
				background: #fff;
			}
		}
	}
}