@import '../../mixins', '../../includes', '../../variables';

$mm_module: '.mm-iconbar';

:root {
    --mm-iconbar-size: #{$mm_iconbarSize};
}

.mm-menu_iconbar {
    &-left {
        .mm-panels,
        .mm-navbars_top,
        .mm-navbars_bottom {
            @if ($mm_IE11Fallbacks) {
                margin-left: $mm_iconbarSize;
            }

            margin-left: var(--mm-iconbar-size);
        }
    }

    &-right {
        .mm-panels,
        .mm-navbars_top,
        .mm-navbars_bottom {
            @if ($mm_IE11Fallbacks) {
                margin-right: $mm_iconbarSize;
            }

            margin-right: var(--mm-iconbar-size);
        }
    }
}

#{$mm_module} {
    @if ($mm_IE11Fallbacks) {
        width: $mm_iconbarSize;
        border-color: $mm_borderColor;
        background: $mm_backgroundColor;
        color: $mm_dimmedTextColor;
    }

    display: none;

    .mm-menu_iconbar-left &,
    .mm-menu_iconbar-right & {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mm-menu_iconbar-left & {
        border-right-width: 1px;
        left: 0;
    }

    .mm-menu_iconbar-right & {
        border-left-width: 1px;
        right: 0;
    }

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;

    width: var(--mm-iconbar-size);
    overflow: hidden;
    box-sizing: border-box;

    border: 0 solid;
    border-color: var(--mm-color-border);
    background: var(--mm-color-background);
    color: var(--mm-color-text-dimmed);
    text-align: center;
}

#{$mm_module}__top,
#{$mm_module}__bottom {
    width: 100%;

    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;

    > * {
        box-sizing: border-box;
        display: block;
        padding: ($mm_btnSize / 4) 0;
    }

    a,
    a:hover {
        text-decoration: none;
    }
}

@if ($mm_include_iconbar_tabs) {
    #{$mm_module}__tab_selected {
        @if ($mm_IE11Fallbacks) {
            background: $mm_emphasizedBackgroundColor;
        }

        background: var(--mm-color-background-emphasis);
    }
}
