@import '../../mixins', '../../includes', '../../variables';

:root {
    --mm-iconpanel-size: #{$mm_iconpanelSize};
}

@include mm_iconpanel_size(1);
@include mm_iconpanel_size(2);
@include mm_iconpanel_size(3);

.mm-panel_iconpanel-first {
    ~ .mm-panel {
        @if ($mm_IE11Fallbacks) {
            width: calc(100% - #{$mm_iconpanelSize});
        }

        width: calc(100% - var(--mm-iconpanel-size));
    }
}

.mm-menu_iconpanel .mm-panels {
    > .mm-panel {
        left: auto;
        transition-property: transform, width;

        &_opened,
        &_opened-parent {
            display: block !important;
        }

        &_opened-parent {
            overflow-y: hidden;
            transform: unset;
        }

        &:not(.mm-panel_iconpanel-first):not(.mm-panel_iconpanel-0) {
            border-left-width: 1px;
            border-left-style: solid;
        }
    }
}

.mm-menu_hidenavbar .mm-panel_opened-parent .mm-navbar,
.mm-menu_hidedivider .mm-panel_opened-parent .mm-divider {
    opacity: 0;
}

@if ($mm_include_iconpanels_blocker) {
    .mm-panel__blocker {
        background: inherit;
        opacity: 0;
        display: block;

        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 3;

        transition: opacity $mm_transitionDuration $mm_transitionFunction;

        .mm-panel_opened-parent & {
            opacity: 0.6;
            bottom: -100000px;
        }
    }
}

@import 'iconpanels.rtl';
