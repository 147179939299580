.fontsize-xxs {
  @include font-size($fontsize-xxs);
}
.fontsize-xs {
  @include font-size($fontsize-xs);
}
.fontsize-sm {
  @include font-size($fontsize-sm);
  @media (max-width: $mobile-breakpoint) {
    @include font-size($fontsize-xs);
    line-height: $fontsize-xs;
  }
}
