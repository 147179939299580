.alertbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    font-size: 0.9rem;
    padding: 0.3rem;
}

.alertbar-highlight { padding: .5rem; align-items: center; height: 40px;  margin-bottom: 1rem;}

.alertbar-group {
    display: inline-flex;
    flex-direction: row;

    @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
    }

}

form.alertbar {
    margin-left: 0.5rem;
}

form.alertbar::after {
    display: none;
}

.resultbar-disabled,
.alertbar-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.alertbar .settings {

    display: inline-block;
    color: #000;
    margin-left: 1rem;
    text-decoration: none;

    span {
        width: 1.25rem;
        display: inline-block;
    }

    @media (max-width: $mobile-breakpoint) {
        margin-top: .5rem;
        margin-left: 0;
        font-size: .8rem;
        line-height: 1rem;

        span {
            width: 2.25rem;
            display: inline-block;

            img {
                padding-left: .9rem;
                height: .9rem;
            }
        }
    }

}

.alertbar .custom-switch-alert,
.alertbar .custom-control-input,
.alertbar .custom-control-label {
    cursor: pointer;
}

.alertbar .custom-control-input:checked~.custom-control-label:before {
    border-color: var(--profile);
    background-color: var(--profile);
}

.alertbar .settings:hover {
    font-weight: bold;
}

.alertbar .settings-small {
    font-size: 80%;
    line-height: 1.5rem;
}

.alertbar-between {
    justify-content: space-between;
}

.alertbar-between .settings {
    margin-right: 0.5rem;
    margin-left: 0;
    text-align: right;
}