$mm_module: '.mm-listitem';

.mm-listview {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
}

#{$mm_module} {
    @if ($mm_IE11Fallbacks) {
        color: $mm_textColor;
        border-color: $mm_borderColor;
    }

    color: var(--mm-color-text);
    border-color: var(--mm-color-border);

    list-style: none;
    display: block;
    padding: 0;
    margin: 0;

    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:after {
        content: '';
        border-color: inherit;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        display: block;
        position: absolute;
        left: $mm_listitemIndent;
        right: 0;
        bottom: 0;
    }

    a,
    a:hover {
        text-decoration: none;
    }
}

%mm_listitem_content {
    @if ($mm_IE11Fallbacks) {
        padding: ($mm_listitemSize - $mm_lineHeight) / 2;
    }

    display: block;
    padding: calc((var(--mm-listitem-size) - var(--mm-line-height)) / 2);
    padding-left: 0;
    padding-right: 0;
    color: inherit;
}

#{$mm_module}__text {
    @extend %mm_listitem_content;
    @include mm_ellipsis;

    padding-left: $mm_listitemIndent;
    padding-right: $mm_padding;
    flex-grow: 1;
    flex-basis: 10%;
}

#{$mm_module}__btn {
    @extend %mm_listitem_content;

    background: rgba(3, 2, 1, 0);
    border-color: inherit;
    width: auto;
    padding-right: $mm_subopenWidth;
    position: relative;

    &:not(.mm-listitem__text) {
        border-left-width: 1px;
        border-left-style: solid;
    }
}

#{$mm_module} {
    &_selected {
        > #{$mm_module}__text {
            @if ($mm_IE11Fallbacks) {
                background: $mm_emphasizedBackgroundColor;
            }

            background: var(--mm-color-background-emphasis);
        }
    }

    &_opened {
        > #{$mm_module}__btn,
        > .mm-panel {
            @if ($mm_IE11Fallbacks) {
                background: $mm_highlightedBackgroundColor;
            }

            background: var(--mm-color-background-highlight);
        }
    }
}
