

// =============================================================================
// Variables
// =============================================================================

$size-home-button: 3.5em;
$position: 2em;
$width-arrow: 0.6em;
$box-shadow-props: 0 0 5px 0 rgba(107, 214, 230, 1);
$max-items: 12;
$userbar-radius: 6px;

$namespace: 'numizon';

// Possible positions for the userbar to exist in. These are set through the
// {% wagtailuserbar 'bottom-left' %} template tag.
$positions: (

    'bottom-left': (
        'vertical': 'bottom',
        'horizontal': 'left',
        'arrow': 'top'
    ),
    'bottom-right': (
        'vertical': 'bottom',
        'horizontal': 'right',
        'arrow': 'top'
    )
);



// =============================================================================
// Wagtail userbar proper
// =============================================================================
.#{$namespace}-userbar-reset {
    all: initial;
}


.#{$namespace}-userbar {
    position: fixed;
    z-index: 800;
    // stylelint-disable-next-line declaration-no-important
    font-size: initial !important;
    line-height: initial;
    margin: 0;
    padding: 0;
    display: block;
    border: 0;
    width: auto;
    height: auto;

}


@media print {
    .#{$namespace}-userbar {
        display: none;
    }
}

// stylelint-disable declaration-no-important
.#{$namespace}-userbar-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size-home-button;
    height: $size-home-button;
    margin: 0 !important;
    overflow: hidden;
    background-color: $catalog;
    border-radius: 50%;
    color: #fff;
    padding: 0 !important;
    cursor: pointer;
    box-shadow: $box-shadow-props, 0 1px 20px 0 rgba(107, 214, 230, 0.7);
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    text-decoration: none !important;
    position: relative;

    .#{$namespace}-userbar.touch.is-active &,
    .#{$namespace}-userbar.no-touch &:hover {
        box-shadow: $box-shadow-props, 0 3px 15px 0 rgba(107, 214, 230, 0.95);
    }

    .#{$namespace}-userbar-help-text {
        position: absolute;
        top: 100%;
        left: 0;
    }

    .#{$namespace}-icon:before {
        transition: color 0.2s ease;
        font-size: 32px;
        width: auto;
        margin: 0;
    }
}

.#{$namespace}-userbar-items {
    display: block;
    list-style: none;
    position: absolute;
    margin: 0;
    min-width: 280px;
    visibility: hidden;
    font-family: $font-family-sans-serif;

    font-size: 14px;
    
    box-sizing: border-box;
    padding-left: 0;
    text-decoration: none;

    .#{$namespace}-userbar.is-active & {
        visibility: visible;
    }
}

.#{$namespace}-userbar-items-border {
    border: 3px solid $catalog;
    border-radius: $userbar-radius;
}

// Arrow
.#{$namespace}-userbar-items:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    border: solid $width-arrow transparent;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);

    .#{$namespace}-userbar.is-active & {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.3s;
    }
}


.#{$namespace}-userbar-nav {
    background: transparent !important;
    padding: 0;
    margin: 0 !important;
    display: block !important;

    .#{$namespace}-action {

        background: transparent;
    }
}

.list-group-sm-padding {
    padding: 0.55rem 0.75rem;
}


// =============================================================================
// Userbar positional classes (tl, tr, bl, br)
// =============================================================================

@each $pos, $attrs in $positions {
    $vertical: map-get($attrs, vertical);
    $horizontal: map-get($attrs, horizontal);
    $arrow: map-get($attrs, arrow);

    .#{$namespace}-userbar--#{$pos} {
        #{$vertical}: $position;
        #{$horizontal}: $position;

        .#{$namespace}-userbar-items {
            #{$vertical}: 100%;
            #{$horizontal}: 0;
            padding-#{$vertical}: $width-arrow * 2;
        }

        .#{$namespace}-userbar-nav .#{$namespace}-userbar__item {
            @if $vertical == 'bottom' {
                transform: translateY(1em);
            } @else {
                transform: translateY(-1em);
            }
        }
        .#{$namespace}-userbar-items:after {
            #{$vertical}: 2px;
            #{$horizontal}: $size-home-button / 2 - $width-arrow / 2;
            border-#{$arrow}-color: $catalog;

            @if $vertical == 'bottom' {
                transform: translateY(-$width-arrow);
            }
            @if $vertical == 'top' {
                transform: translateY($width-arrow);
            }
        }



        &.is-active .#{$namespace}-userbar__item {
            @for $i from 1 through $max-items {
                // stylelint-disable max-nesting-depth

                @if $vertical == 'bottom' {
                    &:nth-last-child(#{$i}) {
                        transition-delay: 0.05s * $i;
                    }
                }

                @if $vertical == 'top' {
                    &:nth-child(#{$i}) {
                        transition-delay: 0.05s * $i;
                    }
                }
            }
        }
    }
}


// =============================================================================
// States
// =============================================================================

// Active state for the list items comes last.
.#{$namespace}-userbar.is-active .#{$namespace}-userbar__item {
    transform: translateY(0);
    opacity: 1;
}
