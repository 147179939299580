
/* REGISTRATION */

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.default {
  font-size: 10px;
  font-family: monospace;
  max-width: 800px;
  margin: 0 auto;
}

.default ul {
  font-size: 18px;
  list-style: none;
}

.good li {
  font-weight: bold;
}



.login-logo {
  margin-bottom: 2rem;
  margin-top: 1rem;
}



#registration {
  width: 100%;
  max-width: 420px;
}

#registration .card {
  border-radius: 8px !important;
}

#registration .card-content {
  border-radius: 8px !important;
}

#registration .row {
  margin-bottom: 0 !important;
}

.inscription-title {
  font-size: 1.3rem;
}

@media (max-width: $mobile-breakpoint) {
  #registration {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}


.login-title {
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 2rem;
  margin-top: .5rem;
}

.footer-login {

  display: block;
  margin: auto;
  width: 100%;
  max-width: 420px;
  margin-top: 3rem;
  padding-top: 2rem;

  text-align: center;
  background: -webkit-linear-gradient(90deg, #ffbb63 0, #210529 50%, #ffbb63 100%);
  background: linear-gradient(90deg, #ffbb63 0, #210529 50%, #ffbb63 100%);
  background-size: 100% 1px;
  background-repeat: no-repeat;


}