
.home_slider { overflow: hidden; height: 400px; max-height: 400px;  }

.home_slider .carousel-inner {
    height: auto;
}
.home_slider .carousel-inner .carousel-item
{
        height:400px;
        color: #fff;
}

.home_slider  .carousel-item {
    height: 100%;
    min-height: 400px;
    background: no-repeat center center scroll;
    background-size: cover;
    }

@media (min-width:$mobile-breakpoint+1) {
  .carousel-item.cover {
    background-size: cover !important;
  }
  .carousel-item.contain {
    background-size: contain !important;
  }
  .carousel-item.left {
    background-position: left !important;
  }
  .carousel-item.center {
    background-position: center !important;
  }
  .carousel-item.right {
    background-position: right !important;
  }
}


.home_slider .carousel-caption {
      top: 0;
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    }

.home_slider .carousel-caption h1
{
    font-size: 3rem !important;
    font-weight: bold !important;
    line-height: 3.3rem !important;

}
.home_slider .carousel-caption h2
{
    font-size: 2.5rem !important; font-weight: bold !important;
    line-height: 2.7rem !important;
}
.home_slider .carousel-caption p
{
  font-size: 2.5rem !important;

   line-height: 2.7rem !important;
}



@media (max-width:$mobile-breakpoint)
{
.home_slider .carousel-caption h1 { font-size: 4rem;}
.home_slider .carousel-caption h1 { font-size: 3rem;}
.home_slider .w100 { width: auto !important; height: 400px; }
  .home_slider  .carousel-item {
    background-size: cover;
  }
}



