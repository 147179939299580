.stats_country {
  padding: 1rem;
}

.stats_country_table {
  margin: 1rem auto;

  .picto {
    width: 40px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .newind {
    padding-left: 10px;
    font-weight: bold;
    color: $catalog;
  }

  .label_country {
    min-width: 250px;
  }
  .label_catalog,
  .label_listing,
  .label_archives {
    width: 250px;
    max-width: 250px;
    padding: 0.5rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: $catalog;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .title {
      padding-left: 10px;
    }
  }

  .label_catalog {
    background-color: $catalog;
    border: 2px solid $catalog;
  }
  .label_listing {
    background-color: $listing;
    border: 2px solid $listing;
  }
  .label_archives {
    background-color: $archives;
    border: 2px solid $archives;
  }

  .inline-flexcol {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
  .sublabel_total {
    width: 120px;
    padding-left: 20px;
    text-align: right;
  }
  .sublabel_date {
    width: 90px;
    padding-left: 20px;
    color: #000;
  }

  .sublabel,
  .data {
    padding-top: 3px;
    padding-bottom: 3px;
    color: #000;
  }

  .sublabel {
    background-color: #fff;
  }

  .sublabel_catalog .sublabel_total,
  .data_catalog .sublabel_total {
    font-weight: bold;
    color: $catalog;
  }

  .sublabel_listing .sublabel_total,
  .data_listing .sublabel_total {
    font-weight: bold;
    color: $listing;
  }

  .sublabel_archives .sublabel_total,
  .data_archives .sublabel_total {
    font-weight: bold;
    color: $archives;
  }

  .data_listing .sublabel_total,
  .data_archives .sublabel_total,
  .data_catalog .sublabel_total {
    font-size: 1rem;
  }

  .data_listing a,
  .data_catalog a,
  .data_archives a {
    display: block;
    width: 100%;
  }
  .data_listing a:hover {
    background-color: $listing;
    .sublabel_total,
    .sublabel_date {
      color: #fff;
    }
  }
  .data_archives a:hover {
    background-color: $archives;
    .sublabel_total,
    .sublabel_date {
      color: #fff;
    }
  }
  .data_catalog a:hover {
    background-color: $catalog;
    .sublabel_total,
    .sublabel_date {
      color: #fff;
    }
  }

  .data_country {
    padding-left: 20px;
    a {
      color: #000;
    }
  }

  .data_catalog,
  .sublabel_catalog {
    margin: 2px 0;
    border-right: 2px solid $catalog;
    border-left: 2px solid $catalog;
  }

  .data_listing,
  .sublabel_listing {
    margin: 0 2px;
    border-right: 2px solid $listing;
    border-left: 2px solid $listing;
  }

  .data_archives,
  .sublabel_archives {
    margin: 0 2px;
    border-right: 2px solid $archives;
    border-left: 2px solid $archives;
  }

  .data_catalog:last-child {
    border-bottom: 1px solid $catalog;
  }

  .row1 {
    background-color: #fff;
  }
  .row2 {
    background-color: $listing-item;
  }
}
