.banknote-titlebar {
  display: flex;
  flex-direction: row;
  align-items: flex-top;
  justify-content: flex-start;
  background-color: #fff;

}

.banknote-titlebar-drop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}

.banknote-titlebar-drop h1 {
  flex-grow: 2;
  padding: 1rem;
  font-size: 2rem;
}

.banknote-titlebar-iid,
.banknote-titlebar-listing {
  padding: 1rem;
  font-size: 1.1rem;

}


.banknote-listing-title {
  font-size: 1.2rem !important;
}

.banknote-listing-title span {
  font-size: 1.3rem;
  font-weight: bold;
}


.show-span-xxl span {
  display: none;
}

@media (min-width: 1281px) {
  .show-span-xxl span {
    display: block;
  }
}
