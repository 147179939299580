.banknote-actions {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-left: .25rem;
    margin-right: .25rem;
    padding-left: 0;
    margin-top: 1rem;

}

.btn-banknote-actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: .9rem;
    margin-left: .25rem;
    margin-right: .25rem;
    line-height: 1.1rem;
    height: 100%;
}


.btn-banknote-actions-lg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: .1rem;
    margin: .25rem;
    line-height: 1.3rem;
    padding: 1rem;
    height: 100%;
}

.btn-banknote-actions-lg div {
    margin: 1rem;
}


.banknote-actions .picto {
    width: 24px;
    margin-right: .3rem;
    margin-top: .1rem;
}

@media (max-width: $tablette_breakpoint) {
    .banknote-actions .picto {
        display: none;
    }
}