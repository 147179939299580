.statisticline {
    min-height: 100px;
}

.statisticline .col {
    font-size: 1.5em;
}

.statisticline .col>span {
    font-size: .9em;
}

.history-list {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    border: 1px solid gray;
    font-size: 1rem;
}

.history-list .e {
    min-width: 50px;
}

.history-list .i {
    min-width: 150px;
}

.history-list .t {
    padding-left: .5rem;
}