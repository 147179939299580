@if ($mm_include_navbars_breadcrumbs) {
    .mm-navbar__breadcrumbs {
        @include mm_ellipsis;

        flex: 1 1 50%;
        display: flex;
        justify-content: flex-start;
        padding: 0 $mm_panelPadding;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        > * {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 6px;
        }

        > a {
            text-decoration: underline;
        }

        &:not(:last-child) {
            padding-right: 0;
        }

        .mm-btn:not(.mm-hidden) + & {
            padding-left: 0;
        }
    }
}
