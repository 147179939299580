.card-a-link {
  border: 2px solid initial;
  &:hover {
    border: 2px solid $news;
    box-shadow: 0 0.5rem 1rem $news;
    .card-image { opacity: .6; }
  }

  .card-date { font-size: .9rem; }
  .card-body { padding: .7rem !important;}

  .underline {
    text-decoration: underline;
  }
  a {
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  .title {
    font-size: 1.2rem;
    color: $news;
  }
  p {
    color: #000;
  }
  .underline {
    color: #000;
  }
}

.article-related {
  font-size: 1.5rem;

  .badge {
    padding: 1rem;
    font-weight: normal;
  }
}

@media (max-width: $mobile-breakpoint) {
  .mobile-card {
    margin: 1rem 0.5rem;
    border: 1px solid $listing-area;

    .no-padding {
      padding: 0;
    }
  }
}

.codered-article,
.codered-article .container {
  background-color: #fff !important;
}

.codered-article .article-body {
  font-size: 0.9rem;
}
