@import '../../mixins', '../../includes', '../../variables';

@if ($mm_include_effects_menuslide) {
    //	Slide menu
    $menu: '.mm-menu_fx-menu-slide';
    #{$menu} {
        transition: transform $mm_transitionDuration $mm_transitionFunction;
    }

    //	Slide menu Left
    .mm-wrapper_opened #{$menu} {
        transform: translate3d(-$mm_subpanelOffset, 0, 0);
    }

    .mm-wrapper_opening #{$menu} {
        transform: translate3d(0%, 0, 0);
    }

    //	Slide menu Right
    $menuright: '#{$menu}.mm-menu_position-right';
    .mm-wrapper_opened #{$menuright} {
        transform: translate3d($mm_subpanelOffset, 0, 0);
    }

    .mm-wrapper_opening #{$menuright} {
        transform: translate3d(0%, 0, 0);
    }
}

@if ($mm_include_effects_panelsnone) {
    //	No effect panels
    .mm-menu_fx-panels-none .mm-panel,
    .mm-panel_fx-none {
        transition-property: none;

        &.mm-panel_opened-parent {
            transform: translate3d(0, 0, 0);
        }
    }
}

@if ($mm_include_effects_panelsslide) {
    //	Slide panels
    .mm-menu_fx-panels-slide-0 .mm-panel_opened-parent {
        transform: translate3d(0, 0, 0);
    }

    .mm-menu_fx-panels-slide-100 .mm-panel_opened-parent {
        transform: translate3d(-100%, 0, 0);
    }
}
