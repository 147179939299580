.splash-product-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100vw;
    min-height: 100vh;
}

.splash-banknote {
    flex: auto;
    width: 200px;
    height: 100px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition-property: scale, width, height;
    transition-duration: 1s;
    transition-timing-function: ease;
    background-color: #fff;
}

.splash-banknote:hover {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    border: 3px solid #fff;
}

.splash-use-border {
    border-left: 1px solid #6f1753;
    border-bottom: 1px solid #6f1753;
}

.splash-fixed-bottom {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #6f1753;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    transition-duration: 1s;
    transition-property: background-color, color, fill;
    transition-timing-function: ease;
}

#splash-our-logo {
    fill: #fff;
    height: 36px;
}

.splash-fixed-bottom:hover {
    background-color: #fff;
    color: #6f1753;
}

.splash-fixed-bottom:hover #splash-our-logo {
    fill: #6f1753;
}

@media (max-width: $tablette_breakpoint) {
    #splash-our-logo { height: 20px; }
    .splash-fixed-bottom { height: 40px; font-size: .9rem; }
}
