// // Specific Buttons

// .btn-archivesdark { color: #fff; }

// Generic Buttons
//

.btn-marginleft {
  margin-left: 0.255em;
}

.btn-wide {
  min-width: 200px;
  padding-left: 2rem;
  padding-right: 2rem;

}

.btn-market-action {
  border-radius: 3px;
  border-color: #ADB1B8 #A2A6AC #8D9096;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  background: linear-gradient(to bottom, #f7dfa5, $subscribe);
  font-weight: bold;
  font-size: .9rem;
}

.btn-amazon-action:hover {
  background: linear-gradient(to bottom, $subscribe, $subscribe);
}

@each $color,
$value in $iam-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color,
$value in $iam-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-listing {
  @include button-variant($listing, $listing);
}

.btn-catalog {
  @include button-variant($catalog, $catalog);
}

.btn-outline-listing {
  @include button-outline-variant($listing);
}

.btn-outline-catalog {
  @include button-outline-variant($catalog);
}

.btn-large {
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

$btn-padding-y-xs: 0.5rem !default;
$btn-padding-x-xs: 0.5rem !default;
$btn-border-radius-xs: 7px !default;

.btn-xs {
  @include button-size($btn-padding-y-xs,
    $btn-padding-x-xs,
    $fontsize-xs,
    $btn-border-radius-xs,
    $btn-border-radius-xs);
  white-space: nowrap;
}

.btn-white-border {
  &.margin-right {
    margin-right: 2px;
  }

  &.bothside {
    border-left: 1px solid $listing-area;
  }

  &.disabled,
  &:disabled {
    border: initial;
    opacity: 0.7;
    @include box-shadow(none);
  }

  // border-right: 1px solid $listing-area;
  // border-left: 1px solid $listing-area;
  border-right: 1px solid $listing-area;
  border-radius: 0;

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: initial;
  }
}

@media (max-width: $mobile-breakpoint) {
  .btn-white-border-mobile {

    &.disabled,
    &:disabled {
      border: initial;
      opacity: 0.7;
      @include box-shadow(none);
    }

    border-right: 1px solid $listing-area;
    border-left: 1px solid $listing-area;
    border-radius: 0;

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: initial;
    }
  }
}