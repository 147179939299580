//
// Pills for profile
//

.nav-pills-profile {
  .nav-link {
    @include border-radius($nav-pills-border-radius);
    font-size: 0.9rem;
    color: $profile;

    @media (max-width: $mobile-breakpoint) {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      font-size: 1rem;
      color: #000;
      padding: 1rem;
    }
  }

  .nav-link.active,
  .show>.nav-link {
    color: #fff;
    background-color: $profile;
  }

  .nav-link:hover {
    background-color: lighten($profile, 20%);
    color: #fff;
  }

  @media (max-width: $mobile-breakpoint) {
    hr {
      margin-top: .5rem;
      margin-bottom: .5rem;
      border-top: 0;
    }
  }

  @media (min-width: $mobile-breakpoint+1) {
    hr {
      margin-top: .75rem;
      margin-bottom: .75rem;
    }
  }
}