.list-title-catalog {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: $catalog;
  text-align: center;
}

.list-wrap {
  display: flex;
  flex-wrap: wrap;
}

.list-wrap-box {
  padding: 1rem;
  margin: .5rem;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 10px;
}

.list-wrap-box-title {
  font-size: 1.1rem;
}

.hr-mini {
  height: 2px;
  padding: 0;
  margin-top: .3rem;
  margin-bottom: .3rem;
}

.list-container {
  padding-bottom: 1rem;
}

@media (max-width: $mobile-breakpoint) {
  .list-wrap-box {
    margin: .2rem;
  }

  .list-title-white {
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin: .2rem;
    font-size: 2rem;
    line-height: 2rem;
  }
}



// private

.img-lock {
  height: 10px;
}