.breadcrump-item.extended,
.breadcrump-item.extended a {
  font-size: .875rem !important;
}

.breadcrump.extended {
  padding: .25rem !important;
  margin-top: .5rem;
  margin-bottom: 0;
}

.breadcrump { background-color: unset !important; }
