// OSCAR PAGE STRUCTURE
// --------------------

// body {
//   background-color: $gray-200;
// }

// .container-fluid {
//   // Here is the page width: editable in page/mixins.less
//   max-width: $oscar-page-width;
// }

// .row {
//   box-sizing: border-box;
// }

// The page container
@media (min-width: 601px) {
  .page .page_inner {
    background: $white;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
}

// Some padding for the inner container
// Also useful for mobile
.page_inner {
  padding: $grid-gutter-width;
  @include clearfix();
  @media (max-width: 600px) {
    padding: 0px;
  }
}

@media (min-width: 601px) {
  .card_size_mobile {
    max-width: 330px;
  }
}

@media (max-width: 600px) {
  .card_size_mobile {
    width: 100%;
  }
}
