/// Rich text enhancements

.richtext-image {
    &.left {
        float: left;
        margin: 0 1em 1em 0;
    }
    &.right {
        float: right;
        margin: 0 0 1em 1em;
    }
    &.full-width {
        width: 100%;
        height: auto;
    }
}
