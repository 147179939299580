.navaction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    min-height: 70px;
    // background-color: #fff;
}
.border-top-navaction {
    border-top: 1px solid darkgray;
}
.border-bottom-navaction {
    border-top: 1px solid darkgray;
}

.navactionfilters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    min-height: 46px;
}
.border-filters {
    background-color: $filters;
}

.navaction-title {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: darkgray;
}

.picto-action {
    height: 28px;
}

.picto-action-toggle {
    height: 20px;
}

.picto-action-mini {
    height: 18px;
}

.dropdown-menu-filter {
    min-width: 200px;
}
