.customer-page { display: flex; padding-top: 30px; padding-bottom: 30px;  justify-content: center; }

.customer-sidebar { max-width: 250px; }
.customer-sidebar ul { flex-direction: column; }
.customer-area { width: 1024px;}
.colsize-banknote { width: 200px; }
.display-web-inline { display: none; }

@media (min-width: 600px) {
.display-web-inline {
    display: inline-block;
}
}

@media (max-width: 600px) { 
    .customer-page { flex-direction: column; padding: 0px !important; }
    .customer-sidebar { max-width: 100%; }
    .customer-sidebar ul { padding: .5rem;  }
    .customer-area { width: 100%;}
    .customer-area .page-header { padding: 15px; }
    .colsize-banknote { width: 150px; }
    .colsize-noleftpadding { padding-left: 0px !important; }

    .customer-area h1 { font-size: 1.3rem; font-weight: bold; color: $profile; }
    
 }

 
.return_account_menu {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 2rem;
  font-size: 1rem;
  text-transform: uppercase;
  color: #000;;
}

.return_list { color: $profile; }