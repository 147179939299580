// Filters
.filter-query {
    width: 100%;
    box-shadow: #fff -6px 0, #fff 6px 0, rgba(0, 0, 0, 0.15) 1px 3px 6px;

    &.fixed {
        position: fixed;
        top: 0;
        z-index: 21;
        width: 100%;

        box-shadow: 0 4px 10px 0 #fff;
        animation-name: slideInDown;
        animation-duration: 0.4s;
        // @media (max-width: 1024px) {
        //   top: $size-navsite-marginpage-md;
        // }
        // @media (max-width: 650px) {
        //   top: $size-navsite-marginpage-mobile;
        // }

        .display-scrolled {
            display: block !important;
        }
    }

    .display-scrolled {
        display: none;
    }

    // &.fixed.iam-cat {
    //   box-shadow: 0 4px 10px 0 $catalog;
    // }
    // &.fixed.iam-lis {
    //   box-shadow: 0 4px 10px 0 $listing;
    // }
}

.filter-query-container {
    @include default-container();
    display: flex;
    flex-direction: column;

    justify-content: flex-start;

    @media (max-width: $mobile-breakpoint) {
        padding-right: 0;
        padding-left: 0;
        font-size: $btn-fontsize-mobile;
    }
}

.filters-line {
    display: flex;
    // height: 40px;
    align-items: center;
    font-size: $btn-fontsize;
    line-height: initial;

    .grow {
        flex-grow: 2;
    }

    .hamburger svg,
    .picto {
        width: 16px;
    }

    .h1 {
        padding: 0;
        padding-top: 2px;
        margin: 0;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.5rem;
    }

    .btn {
        height: 100%;
        font-size: $btn-fontsize;
    }

    .filters-legend {
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 0.2rem;
        padding-left: .6rem;
        font-size: $btn-fontsize;
        // line-height: 1.5;
        color: $listing-area;

    }

    .grow {
        flex-grow: 2;
    }

    .catalog-jump,
    .listing-jump,
    .right {
        justify-self: flex-end;
    }

    .listing-jump {
        font-weight: bold;
    }

    .mobile-fullwidth {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
    }

    @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
        justify-content: space-between;

        .hamburger svg {
            width: 10px;
        }

        .mobile-fullwidth {
            flex-grow: 2;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid white;
        }

        // .legend-menu {
        //   // display: inline-block;
        //   // max-width: 120px;

        //   // overflow-x: hidden;
        //   // font-size: .91rem;
        //   // overflow-wrap: break-word;

        // }
        .btn {
            padding: 0.2rem 0.5rem;
            font-size: $btn-fontsize;
        }

        .btn-large-right {
            width: 100%;
        }

        .right {
            flex-grow: 3;
        }

        .right .btn {
            width: 100%;
        }

        .btn .label {
            display: none;
        }
    }
}

.filters-chexkbox {
    display: flex;
    align-items: center;
}

.filters-label-badge {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    //min-width: 200px;
    margin-left: 0.5rem;

	&.filters-label-fullwidth {
		margin-left: 0 !important;
	}

    .label {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        flex-grow: 2;
    }

    .filter-label {
        min-width: 100px;

        &.small {
            font-size: 0.9rem;
        }
    }

    .badge {
        font-weight: normal;
        margin-left: 0.5rem;
    }
}

input[type='checkbox']:disabled+.filters-label-badge {
    opacity: 0.5;
}

// #detail-sales:target::before,
// #detail-sales::before,
// #detail-sales:target::before,
// #detail-variant:target::before,
// #detail-cotation:target::before
//  {
//   display: block;
//   height: 160px; /*fixed header height*/
//   margin: -160px 0 0; /*negative fixed header height*/
//   content: "aaa";
// }
#detail-sales:target::before,
#detail-variant:target::before,
#detail-cotation:target::before,
a[detail-sales],
a[detail-variant],
a[detail-cotation] {
    display: block;
    padding-top: 50px;
    margin-top: -50px;
    content: '';

    @media (max-width: $mobile-breakpoint) {
        padding-top: 100px;
        margin-top: -100px;
    }
}