@import '../../mixins', '../../includes', '../../variables';

.mm-menu_selected {
    &-hover,
    &-parent {
        .mm-listitem__text,
        .mm-listitem__btn {
            transition: background-color $mm_transitionDuration
                $mm_transitionFunction;
        }
    }

    &-hover {
        .mm-listview:hover > .mm-listitem_selected:not(:hover) {
            > .mm-listitem__text {
                background: none;
            }
        }
        .mm-listitem__text,
        .mm-listitem__btn {
            &:hover {
                @if ($mm_IE11Fallbacks) {
                    background: $mm_emphasizedBackgroundColor;
                }

                background: var(--mm-color-background-emphasis);
            }
        }
    }

    &-parent {
        .mm-panel_opened-parent .mm-listitem:not(.mm-listitem_selected-parent) {
            > .mm-listitem__text {
                background: none;
            }
        }
        .mm-listitem_selected-parent {
            > .mm-listitem__text,
            > .mm-listitem__btn {
                @if ($mm_IE11Fallbacks) {
                    background: $mm_emphasizedBackgroundColor;
                }

                background: var(--mm-color-background-emphasis);
            }
        }
    }
}
