@if ($mm_include_vertical) {
    .mm-listitem_vertical > .mm-panel {
        display: none;
        width: 100%;
        padding: $mm_padding 0 $mm_padding $mm_padding;

        transform: none !important;

        &:before,
        &:after {
            content: none;
            display: none;
        }
    }

    .mm-listitem_opened > .mm-panel {
        display: block;
    }

    .mm-listitem_vertical {
        > .mm-listitem__btn {
            @if ($mm_IE11Fallbacks) {
                height: $mm_listitemSize;
            }

            height: var(--mm-listitem-size);
            bottom: auto;
        }

        .mm-listitem:last-child:after {
            border-color: transparent;
        }
    }

    .mm-listitem_opened {
        > .mm-listitem__btn:after {
            transform: rotate(225deg);
            right: $mm_listitemIndent - 1;
        }
    }
}
