// Page / sub headers consistant
.sub-header {
    padding-bottom: ($base-margin-bottom / 2) - 1;
    border-bottom: 1px solid $gray-200;
}

.page-header {
    border-bottom: 1px solid $gray-200;
    padding-bottom: 0.4rem;

    h1,
    h2 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

// Sub header a little lighter

.sub-header {

    h1 {
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: bold;
    }
}


.sub-header {

    h2,
    h3,
    h4 {
        font-size: $font-size-base * 1.6;
        line-height: $line-height-base * 1.5;
        font-weight: normal;
        color: $gray-800;
    }
}


.customer-order .order_product_title {
    color: #000;
    padding: 0;
    padding-left: 1rem;
}

.customer-order {
    font-size: .9rem;
}

.order-price-size {
    width: 100px;
    border-left: 1px solid #dee2e6;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.table-price-wide {
    background-color: $subscribe-light;
}

.table-price {
    background-color: $subscribe-light;
    max-width: 450px;

    @media (max-width: 400px) {
        max-width: 100%;
    }
}