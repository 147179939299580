// Numizon Colors
// .iam-catalog

.group-contenthome {
    background-color: $home-content !important;
}

.color-white {
    color: #fff;
    fill: #fff;
}

.color-black {
    color: #000;
    fill: #000;
}

.color-navline {
    color: $navline;
    fill: $navline;
}

.color-gray {
    color: darkgray;
    fill: darkgray;
}


.color-black-hover {
    color: #000;
    fill: #000;

    &:hover {
        color: $navline;
        fill: $navline;
    }
}

.bg-white {
    background-color: #fff;
}

.bg-catalog-content {
    background-color: $catalog-content;
}

.bg-listing-area {
    background-color: $listing-area;
}

.bg-light2 {
    background-color: $light2;
}

@each $color, $value in $iam-colors {
    .iam-#{$color} {
        &.white {
            color: #fff;

            a {
                color: #fff;
            }
        }

        &.border-bottom {
            border-bottom: 1px solid $value;
        }
        &.border-top {
            border-top: 1px solid $value;
        }
        &.bg,
        .bg {
            background-color: $value;

            &.dark {
                background-color: darken($value, 10%);
            }

            &.darken {
                background-color: darken($value, 20%);
            }

            &.light {
                background-color: lighten($value, 10%);
            }

            &.lighten {
                background-color: lighten($value, 20%);
            }

            &.lighthover {
                background-color: lighten($value, 10%);

                &:hover {
                    background-color: lighten($value, 20%);
                }
            }

            &.darkhover {
                background-color: darken($value, 10%);

                &:hover {
                    background-color: darken($value, 20%);
                }
            }

            &.hover:hover {
                background-color: darken($value, 10%);
            }
        }

        &.color,
        .color {
            color: $value;
        }

        &.fill,
        .fill {
            fill: $value;
        }

        &.links,
        .links {
            color: $value;

            @include hover() {
                color: darken($value, 20%);
            }
        }

        .active-white {
            color: #fff;
            background-color: $value;
        }

        .active-black {
            color: #000;
            background-color: $value;
        }
    }

    .iam-#{$color}-bg {
        background-color: $value;

        &.dark {
            background-color: darken($value, 10%);
        }

        &.darken {
            background-color: darken($value, 40%);
        }

        &.light {
            background-color: lighten($value, 10%);
        }

        &.darkhover {
            background-color: darken($value, 10%);

            &:hover {
                background-color: darken($value, 40%);
            }
        }

        &.hover:hover {
            background-color: darken($value, 10%);
        }

        &.link {
            color: white;

            &:hover {
                color: white;
            }
        }
    }

    .iam-#{$color}-color {
        color: $value;
    }

    .iam-#{$color}-fill {
        fill: $value;
    }

    .iam-#{$color}-links {
        a {
            color: $value;

            @include hover() {
                color: darken($value, 20%);
            }
        }
    }

    .iam-#{$color}-link {
        color: $value;

        @include hover() {
            color: darken($value, 20%);
        }
    }
}

// BORDER

.border-catalog {
    border-color: $catalog;
    border-style: solid;
    border-radius: 5px;
}

.border-listing {
    border-color: $listing;
    border-style: solid;
    border-radius: 5px;
}
