/* HOME CATALOG GRID */
.home-catalog-img {
  border-radius: 50px 50px 0px 0px;
}

.home-catalog-grid {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.home-catalog-grid li a {
  margin: 0.25rem;
  border-radius: 50px 50px 0px 0px;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.home-catalog-grid .badge {
  font-size: 1.6px;
}

.home-catalog-grid-tiny .home-catalog-img {
  height: 100px;
}

.home-catalog-grid-tiny a {
  font-size: 0.9rem !important;
  width: 150px;
  overflow: hidden;
}
