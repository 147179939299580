  .article-list-header {
            padding-top: 1rem;
            xpadding-bottom: 1rem;
            background-color: #951e62;
            color: #fff;
            margin-bottom: 2rem;
            border-top: 1px solid #fff;
            border-bottom: 2px solid #e95420;
        }

.article-list-header h1, .article-list-header h4 {
    color: #fff;
}

.article-list-header .nav-tabs {
    border-color: #e95420;
    color: #fff;
}

.article-list-header .nav-tabs .nav-link {
    color: #fff;
}

.article-list-header .nav-tabs .nav-link:hover {
    background-color: #e95420;
    color: #fff;
}

.article-list-header .nav-tabs .active,
.article-list-header .nav-tabs .disabled {
    background-color: #e95420 !important;
    color: #fff !important;
}