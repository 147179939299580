.dropdown-menu {
    margin-top: 0 !important;
}

// Dropdown

.dropdown-toggle-extended {
    white-space: nowrap;
}

.dropdown-toggle-extended::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    margin-top: 0.25rem;
}

.dropdown-toggle-extended:empty::after {
    margin-left: 0;
}

.dropdown-toggle::after {
    margin-top: 0.25rem;
}

// Hover

@include media-breakpoint-up(md) {
    .dropdown-hover:hover > .dropdown-menu,
    .dropdown-hover:hover .dropdown-menu,
    .dropdown-hover:hover > .dropdown-menu.show,
    .dropdown-hover:hover .dropdown-menu.show {
        display: block;
    }
}
