#q {
    display: none;
    justify-content: flex-start;
    font-weight: bold;
    cursor: pointer;
    background-color: $gray-100;
    border-bottom: 1px solid $dark2;
    border-radius: 1px;
}

#myq {
    display: flex;
    justify-content: space-between;
    margin-top: 0.1rem;
    margin-bottom: .5rem;
    margin-left: .5rem;
    font-size: 0.9rem;
    font-weight: normal;
    color: #000;

    .filter-action {
        margin-right: 0.5rem;
    }

    .filter-action-link {
        display: inline-block;
        min-width: 90px;
        padding-top: 0.5rem;
        padding-bottom: 0.3rem;
        padding-left: .7rem;
        padding-right: .7rem;
        // border: 1px solid #626262;
        margin: 0;
        font-size: 0.8rem;
        line-height: 1rem;

        text-align: center;
        cursor: pointer;
        color: #626262;
		background-color: #fff;
        border-radius: 50px;

        &:hover {
            text-decoration: none;
            background-color: #fff;
            color: $navline;
        }

        // @media (max-width: 512px) {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     justify-content: center;

        //     width: 90px;
        // }
    }

    .filter-count {
        display: inline;
        font-size: 0.8rem;
        padding-top: 0.3rem;
        padding-bottom: 0.1rem;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        margin-right: 0.5rem;
        color: darken($navline, 5%);
        font-weight: bold;
        // color: #fff;
        // color: #FFF;
        // background-color: $navline;
        // border-radius: 25px;

        line-height: 1rem;
    }

  

    .filter-info {
        padding: 0.3rem;
        margin-right: 0.5rem;
        background-color: $listing-item;
    }
}

.filter-tags {
    display: inline-block;
    font-size: 0.8rem;
    padding-top: 0.3rem;
    padding-bottom: 0.1rem;
    margin-bottom: 0.5rem;
    padding-left: 0.8rem;
    padding-right: 0;
    margin-right: 0.5rem;
    color: #fff;
    background-color: darken($navline, 50%);
    // color: #FFF;
    // background-color: $navline;
    border-radius: 25px;
    cursor: pointer;
    line-height: 1rem;
}

.filter-tags:hover {
    background-color: darken($navline, 10%);
    color: #fff;
    opacity: 0.75;
}

.filter-tags:hover .close:not(:disabled):not(.disabled) {
    opacity: 0.75;
    color: #fff;
}

.filter-tags .close {
    font-size: 1.2rem !important;
    padding-left: 0.3rem;
    padding-right: 0.8rem;
    color: #fff;
    //text-shadow: 0 1px 0 #000;
    line-height: 0.9rem;
}

.filter-info-group.archives span {
    background-color: $archives;
}

.filter-info-group {
    margin-left: .5rem;
    margin-bottom: .5rem;
}

// Result list
//

.listing-area {
    height: 100%;
    min-height: 100vh;
    color: #000;
    background-color: $listing-area;
}

.pageresult {
    display: flex;
    flex-direction: column;
    padding-top: 0.5em;
    padding-top: 1em;
    padding-right: 0.5em;
    padding-bottom: 1rem;
    padding-left: 0.5em;
}

.vsonumizon-checkbox {
    font-weight: bold;
}

.pageresult-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 2rem;
    padding-left: 2rem;
    color: $navline;

    &.flexmargin {
        justify-content: flex-start;
        padding-top: 1rem;
        padding-bottom: 1rem;

        div {
            margin-right: 1rem;
        }
    }

    &.archives {
        color: $archives;
    }

    @media (max-width: $mobile-breakpoint) {
        padding-right: 0.5rem;
        padding-bottom: 0rem;
        padding-left: 0.5rem;
    }

    .listing-order {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0.3rem;
        font-size: 0.9rem;

        .label {
            width: 150px;
            padding-right: 5px;
            font-weight: bold;
            text-align: right;

            @media (max-width: $mobile-breakpoint) {
                display: none;
            }
        }
    }

    .count {
        display: flex;
        align-items: center;
        padding: 0.5rem 0rem;
        font-size: 0.9rem;
        font-weight: bold;
        line-height: 1rem;
        border-radius: 50px;

        @media (max-width: $mobile-breakpoint) {
            flex-direction: row;
        }

        &.catalog {
            color: $catalog;
        }

        &.archives {
            color: $archives;
        }

        &.lis {
            color: $listing;
        }
    }
}

// Result grid
//

.w-gridlisting {
    width: 25%;
    padding: 0.25rem;

    @media (max-width: $tablette-breakpoint) {
        width: 50%;
        padding: 0.25rem;
    }

    @media (max-width: $mobile-breakpoint) {
        width: 100%;
        padding: 0;
    }
}

// Display more
//

.spinner-area {
    text-align: center;
    padding: 5rem;
    width: 100%;
    margin: auto;
}

.resultaction {
    display: none;
    width: 100%;
}

.listingmore {
    display: block;
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
    text-align: center;
}

#listingmore {
    padding: 1rem 2rem;
    border-radius: 50px;
}

#selectorder {
    font-weight: bold;
    color: $listing;
}

#selectorder * {
    color: $listing;
}
