$mm_module: '.mm-navbar';

@if ($mm_include_navbars_tabs) {
    //  All tabs.
    #{$mm_module}_tabs {
        > * {
            padding: 0 $mm_padding;
            border: 1px solid transparent;
        }
    }

    //  Selected tab.
    #{$mm_module}__tab_selected {
        @if ($mm_IE11Fallbacks) {
            background: $mm_backgroundColor;
            color: $mm_textColor;

            &:not(:first-child) {
                border-left-color: $mm_borderColor;
            }

            &:not(:last-child) {
                border-right-color: $mm_borderColor;
            }
        }

        background: var(--mm-color-background);

        // TODO: not specific enough :/ overriden by .mm-menu a :/ :/ :/
        color: var(--mm-color-text);
        // /TODO
        &:not(:first-child) {
            border-left-color: var(--mm-color-border);
        }

        &:not(:last-child) {
            border-right-color: var(--mm-color-border);
        }
    }
}

//  Navbars at the top.
@if ($mm_include_navbars_top) {
    .mm-navbars_top {
        #{$mm_module}_tabs {
            border-bottom: none;
        }

        //  Borders.
        #{$mm_module}_tabs > * {
            @if ($mm_IE11Fallbacks) {
                border-bottom-color: $mm_borderColor;
            }

            border-bottom-color: var(--mm-color-border);
        }

        #{$mm_module}__tab_selected {
            @if ($mm_IE11Fallbacks) {
                border-top-color: $mm_borderColor;
            }

            border-top-color: var(--mm-color-border);
            border-bottom-color: transparent;
        }

        //  Backgrounds
        &.mm-navbars_has-tabs {
            #{$mm_module} {
                @if ($mm_IE11Fallbacks) {
                    background: $mm_emphasizedBackgroundColor;
                }

                background: var(--mm-color-background-emphasis);
            }

            #{$mm_module}_tabs ~ #{$mm_module} {
                @if ($mm_IE11Fallbacks) {
                    background: $mm_backgroundColor;
                }

                background: var(--mm-color-background);
            }
        }
    }
}

@if ($mm_include_navbars_bottom) {
    .mm-navbars_bottom {
        #{$mm_module}_tabs {
            &:first-child {
                border-top: none;
            }
        }

        //  Borders.
        #{$mm_module}_tabs > * {
            @if ($mm_IE11Fallbacks) {
                border-top-color: $mm_borderColor;
            }

            border-top-color: var(--mm-color-border);
        }

        #{$mm_module}__tab_selected {
            @if ($mm_IE11Fallbacks) {
                border-bottom-color: $mm_borderColor;
            }

            border-bottom-color: var(--mm-color-border);
            border-top-color: transparent;
        }
        //  Backgrounds
        &.mm-navbars_has-tabs {
            #{$mm_module} {
                @if ($mm_IE11Fallbacks) {
                    background: $mm_backgroundColor;
                }

                background: var(--mm-color-background);
            }

            #{$mm_module}_tabs,
            #{$mm_module}_tabs ~ #{$mm_module} {
                @if ($mm_IE11Fallbacks) {
                    background: $mm_emphasizedBackgroundColor;
                }

                background: var(--mm-color-background-emphasis);
            }
        }
    }
}
