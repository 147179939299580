$mm_module: '.mm-btn';

#{$mm_module} {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
    width: $mm_btnSize;
    padding: 0;

    &:before,
    &:after {
        @if ($mm_IE11Fallbacks) {
            border: 2px solid $mm_dimmedTextColor;
        }

        border: 2px solid var(--mm-color-button);
    }
}

%mm_btn_arrow {
    content: '';
    border-bottom: none;
    border-right: none;
    box-sizing: content-box;
    display: block;
    width: 8px;
    height: 8px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
}

#{$mm_module} {
    &_prev:before {
        @extend %mm_btn_arrow;
        @include mm_btn_arrow_prev;
    }

    &_next:after {
        @extend %mm_btn_arrow;
        @include mm_btn_arrow_next;
    }

    &_close {
        &:before,
        &:after {
            content: '';
            box-sizing: content-box;
            display: block;
            width: 5px;
            height: 5px;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;

            transform: rotate(-45deg);
        }
        &:before {
            border-right: none;
            border-bottom: none;
            right: $mm_listitemIndent - 2;
        }
        &:after {
            border-left: none;
            border-top: none;
            right: $mm_listitemIndent + 5;
        }
    }
}
