.card-masonry {
    display: flex;
    flex-flow: row wrap;
    margin-left: -8px;
    /* Adjustment for the gutter */
}

.card-masonry-horizontal {
    flex: auto;
    min-height: 350px;
    min-width: 150px;
    margin: 0 8px 8px 0;
    /* Some gutter */
    overflow: hidden;
}

.card-masonry-horizontal {

    & :nth-child(4n+1) {
        width: 250px;
    }

    & :nth-child(4n+2) {
        width: 325px;
    }

    & :nth-child(4n+3) {
        width: 180px;
    }

    & :nth-child(4n+4) {
        width: 380px;
    }

}
.topmargin { margin-top: 5rem; }