// Banknote menu
//

.mobile-menu-banknote .banknotemenu-linecontainer {
  display: flex;
  flex-direction: column;
  // flex-wrap: row;
  height: calc(100vh - 50px);
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.flexcol {
  display: flex;
  flex-direction: column;
}

.mobile-menu-banknote.lis .mm-navbar {
  font-size: 1.3rem;
  font-weight: normal;
  color: #fff;

  background-color: $listing-dark;
}
.mobile-menu-banknote.inv .mm-navbar {
    font-size: 1.3rem;
    font-weight: normal;
    color: #fff;
  
    background-color: $inv;
  }
.mobile-menu-banknote.cat .mm-navbar {
  font-size: 1.3rem;
  font-weight: normal;
  color: #fff;

  background-color: $catalog-dark;
}
.mobile-menu-banknote.archives .mm-navbar {
  font-size: 1.3rem;
  font-weight: normal;
  color: #fff;

  background-color: $archives-dark;
}
.banknotemenu {
  @include default-container();
  padding-bottom: 2rem;

  &.brothers {
    padding: 1rem;
    margin-top: 1rem;
    background-color: #fff;

    h3 {
      margin-bottom: 1rem;
    }
  }
}

.banknotemenu-linecontainer {
  padding: 0.5rem;
}

.banknotemenu-linebrothers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.banknotemenu-line {
  // display: flex;
  // flex-direction: column;
  // background-color: #fff;

  &.active-iid {
    order: -1;
    margin-bottom: 1rem;
    .banknotemenu-title {
      font-weight: bold;
    }
  }
}

.banknotemenu-line:hover {
  background-color: #f8f9fa;
}

// .mobile-menu-banknote .banknotemenu-line {
//   background-color: initial;
// }

.banknotemenu-title {
  align-self: center;
  width: 100%;
  min-width: 100%;
  padding: 3px;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 0.91rem;

  color: $navline;
  border-top: 1px solid $navline;
}

.banknotemenu-images {
  display: inline-flex;

  flex-wrap: wrap;
  a {
    position: relative;
    margin: 3px 5px;
    border: 2px solid transparent;
    min-height: 70px;
    min-width: 70px;
  }
  a:hover {
    border: 2px solid $navline;
  }
  .active-lis,
  .active-lis:hover {
    border: 4px solid $listing;
  }
  .active-cat,
  .active-cat:hover {
    border: 4px solid $catalog;
  }
  .nb {
    position: absolute;
    top: 0;
    right: 0;
  }
  .disabled {
    opacity: 0.5;
  }
}

.banknotemenu-images img {
  width: auto;
  height: 70px;
}

.banknotemenu-images a:has(> .imgerror) {
  background-color: lightgray;
  opacity: 0.5;
}
