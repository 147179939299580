// Classic Container
//

@mixin default-container() {
  width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  margin: auto;
  @media (max-width: $mobile_breakpoint) {
    padding-right: .3rem;
    padding-left: .3rem;
  }
  @media (min-width: $mobile_breakpoint+1) and (max-width: $tablette_breakpoint) {
	  padding-right: 1rem;
	  padding-left: 1rem;
  }
  @media (min-width: 1280px) {
    max-width: 1200px;
  }
}
@mixin default-container-nopadding() {
  width: 100%;
  // padding-right: 2rem;
  // padding-left: 2rem;
  margin: auto;
  @media (max-width: $mobile_breakpoint) {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  @media (min-width: 1280px) {
    max-width: calc(1200px - 4rem);
  }
}

.classic-container {
  @include default-container();
  &.maxheight,
  &.screen-area {
    min-height: 100vh;
    padding-bottom: 3rem;
  }
  &.limitwidth {
    max-width: 900px;
    @media (max-width: $tablette-breakpoint) {
      max-width: 100%;
    }
  }
}

.maxwidth-container {
  max-width: 800px;
  @media (max-width: $tablette-breakpoint) {
    max-width: 100%;
  }
}

.wrap-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
