
.numizon-comment-menuline { display: flex; align-content: flex-end; }
.numizon-comment-userdate { 
    font-size: .9rem;
    color: darkgray;
}

.numizon-comment-reactions { padding-top: .25rem; font-size: .9rem !important  }
.numizon-comment-reaction { display: inline-flex; align-items: flex-start; font-size: .9rem;  color: darkgray; }
.numizon-comment-reaction svg { height: 16px; }
.numizon-comment-reaction:hover { text-decoration: none; }

.numizon-comment-connect { font-size: 1rem; }

#comments .hr {
    margin-top: 0.2rem;
    margin-bottom: 1rem !important;
}

