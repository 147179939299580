@import '../../mixins', '../../includes', '../../variables';

$mm_module: '.mm-counter';

#{$mm_module} {
    @if ($mm_IE11Fallbacks) {
        color: $mm_dimmedTextColor;
    }

    display: block;
    padding-left: $mm_padding * 2;
    float: right;
    text-align: right;
    color: var(--mm-color-text-dimmed);
}

@if ($mm_include_searchfield) {
    .mm-listitem_nosubitems > #{$mm_module} {
        display: none;
    }
}

@import 'counters.rtl';
