$scrollbarBG: #cfd8dc;
$thumbBG: #90a4ae;

@media (max-width: $mobile-breakpoint) {
    .scrolling-horizontal-visible {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: thin;
        scrollbar-color: $thumbBG $scrollbarBG;

        &::-webkit-scrollbar {
            width: 11px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $thumbBG;
            border-radius: 6px;
            border: 3px solid $scrollbarBG;
        }
    }
    .scrolling-horizontal {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar { 
            display: none;  /* Chrome Safari */
        }
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */        
    }

}


