.hero_title {
    font-size: 2.5rem;
    margin-bottom: .5rem;
}

.hero_p {
    font-size: 1.25rem;
    margin-bottom: 0rem;
}

.hero_padding { padding: 1rem; }

.picto-titlebar {
    width: 100px;
    height: 100px;
}

@media (max-width: $tablette-breakpoint) {
    .hero_title {
        font-size: 1.5rem;
        margin-bottom: .25rem;
    }
    
    .hero_p {
        font-size: 1rem;
        margin-bottom: .25rem;
    }
}

@media (max-width: $mobile-breakpoint) {
    .picto-titlebar {
        display: none;
    }
}
