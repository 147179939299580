.w-min150 {
    min-width: 150px;
}
.w-min200 {
    min-width: 200px;
}

.no-scroll {
    margin: 0;
    height: 100%;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
}

.border-light-left {
    border-left: 1px solid $light2;
}
.border-light-right {
    border-right: 1px solid $light2;
}


.collapse-icon::after {
    content: '\2795';
    font-size: 14px;
    color: #000000;
    float: right;
    margin-left: 5px;
    margin-top: 2px;
}

[aria-expanded="true"].collapse-icon::after {
    content: "\2796";
}