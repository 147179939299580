@import '../../mixins', '../../includes', '../../variables';

.mm-menu_autoheight {
    &:not(.mm-menu_offcanvas) {
        position: relative;
    }

    &.mm-menu_position {
        &-top,
        &-bottom {
            max-height: percentage($mm_menuHeight);
        }
    }

    &-measuring {
        .mm-panel {
            display: block !important;
        }

        .mm-panels > .mm-panel {
            bottom: auto !important;
            height: auto !important;
        }

        @if ($mm_include_vertical) {
            .mm-listitem_vertical:not(.mm-listitem_opened) .mm-panel {
                display: none !important;
            }
        }
    }
}
