@import '../../mixins', '../../includes', '../../variables';

[class*='mm-menu_columns-'] {
    transition-property: width;

    .mm-panels {
        > .mm-panel {
            right: auto;
            transition-property: width, transform;

            &_opened,
            &_opened-parent {
                display: block !important;
            }
        }
    }
}

[class*='mm-panel_columns-'] {
    border-right: 1px solid;
    border-color: inherit;
}

.mm-menu_columns-1 .mm-panel_columns-0,
.mm-menu_columns-2 .mm-panel_columns-1,
.mm-menu_columns-3 .mm-panel_columns-2,
.mm-menu_columns-4 .mm-panel_columns-3 {
    border-right: none;
}

@include mm_columns_size(0);
@include mm_columns_size(1);
@include mm_columns_size(2);
@include mm_columns_size(3);
@include mm_columns_size(4);

@if ($mm_include_positioning_top or $mm_include_positioning_bottom) {
    [class*='mm-menu_columns-'] {
        &.mm-menu_position {
            &-bottom,
            &-top {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
            }
        }
    }
}

@if ($mm_include_positioning_front) {
    .mm-wrapper_opening [class*='mm-menu_columns-'] {
        &.mm-menu_position {
            &-front {
                transition-property: width, min-width, max-width, transform;
            }
        }
    }
}
