@import '../../mixins', '../../includes', '../../variables';

.mm-menu_keyboardfocus {
    &,
    &.mm-menu_opened ~ .mm-wrapper__blocker {
        a:focus {
            @if ($mm_IE11Fallbacks) {
                background: $mm_emphasizedBackgroundColor;
            }

            background: var(--mm-color-background-emphasis);
            outline: none;
        }
    }
}

.mm-wrapper__blocker {
    .mm-tabstart {
        cursor: default;
        display: block;
        width: 100%;
        height: 100%;
    }
    .mm-tabend {
        opacity: 0;
        position: absolute;
        bottom: 0;
    }
}
