.countryname {
  display: flex;
  justify-content: space-between;
}
.countryname .link {
  flex-grow: 2;
}
.countryname .info {
  max-width: 65px;
  justify-content: flex-end;
  display: inline-flex;
}
.mm-counter {
  display: inline-block;
  min-width: 40px;
  font-size: 0.9rem;
}

/*
* Country Menu as dropdown
*/
@media (min-width: $mobile-breakpoint+1) {
  .filter-query.fixed .country-menu {
    top: 25px !important;
  }
}

.mobile-drawer {
  position: fixed;
  top: 0 !important;
  right: 0;
  bottom: 0;
  left: 0 !important;
  z-index: 5000;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch; /*enables momentum scrolling in iOS overflow elements*/

  .menu-body {
    height: 100%;
  }
  .mm-close-country,
  .mm-close {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    padding: 0.5rem 0.85rem;
    font-size: 0.9rem;
    color: #fff;
    background-color: $catalog;

    border-bottom: 1px solid #000;
  }
  &.dropdown-menu {
    padding-top: 0;
    margin-top: 0;
  }

  .menu-tab {
    margin-top: 35px;
  }
}

.country-menu,
.banknote-menu,
.mega-menu {
  @include default-container-nopadding();
  top: 95px;
  background-color: transparent;
  border: initial;

  @media (min-width: $mobile-breakpoint+1) {
    .mobile-display {
      display: none;
    }
    .mobile-hide {
    }
  }
  @media (max-width: $mobile-breakpoint) {
    .mobile-display {
      display: block;
    }
    .mobile-hide {
      display: none;
    }
  }

  &.cat .menu-body {
    border: 5px solid $catalog;
    border-top-width: 2px;
    @media (max-width: 650px) {
      border-top-width: 5px;
    }
  }
  &.lis .menu-body {
    border: 5px solid $catalog;
    border-top-width: 2px;
    @media (max-width: $mobile-breakpoint) {
      border-top-width: 5px;
    }
  }

  .menu-body {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    width: 100%;

    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
      min-height: initial;
    }
  }

  .menu-tab {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 200px;
    min-width: 200px;
    background-color: #efefef;

    &.menu-tab-large {
      width: 700px;
      min-width: 700px;
    }

    @media (max-width: $mobile-breakpoint) {
      flex-direction: row;
      width: 100%;
      li {
        width: 50%;
      }
    }
  }

  .mm-tab-link {
    display: block;
    width: 100%;
    padding: 0.5rem;

    font-size: 0.9rem;
    color: $navline;
    border-bottom: 1px solid #c3c3c3;

    &:last-childx {
      border-bottom: initial;
    }
    &:hover {
      background-color: #ffff; // $menu-item;
    }
    &.catalog {
      color: $catalog;
    }
    &.active {
      // font-weight: bold;
      // background-color: $menu-active;
      background-color: #fff;
    }
    &.keepselected {
      font-weight: bold;
      color: #fff;
      background-color: darken($catalog, 9%);
    }
    &.lis {
      background-color: darken($listing, 9%);
    }
  }
}

.tab-content.fullwidth {
  width: 100%;
}

.menu-list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.menu-list-group-title {
  width: 100%;
  padding: 1rem;
  font-size: 0.9rem;
  font-weight: bold;

  border: 0;
  border-bottom: 1px solid $catalog;
}
.not-isvisible {  opacity: 0.6; }
.menu-list-group-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 33.33%;

  @media (max-width: $mobile-breakpoint) {
    width: 100%;
  }

  .list-group-item {
    height: 100%;
    padding: 1rem 0.5rem;
    font-size: 0.9rem;
    line-height: 1.1rem !important;
    border-radius: initial;

    .legend {
      float: left;
    }
    .badge {

      width: 45px;
      font-size: .8rem;
      float: right;
      font-weight: normal;
      padding-top: .3rem;
    }
    &.active {
      font-weight: normal;
      color: #fff;
      background-color: $catalog;
      border-color: $catalog;
    }
    &.lis {
      background-color: $listing;
      border-color: $listing;
    }
  }
}
