.banknote-detail-header {
    color: darkgray;
    padding-left: 1rem;
}

/****
GRID SYSTEM
*/

.listing-banknote,
.inventory-banknote {
    display: flex;
    flex-direction: column;
    max-width: 600px;
}
@media (min-width: $mobile-breakpoint+1) {
    .listing-banknote {
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: minmax(260px, auto) 1fr;
        grid-gap: 10px;
        grid-template-areas:
            'colleft colright'
            'colleft footright';
    }

    .inventory-banknote {
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: 20px 1fr;
        grid-gap: 10px;
        grid-template-areas:
            'colleft colright'
            'colleft footright';
    }

    .banknote-shop,
    .banknote-detail-header {
        grid-area: colright;
    }
    .banknote-detail {
        grid-area: footright;
    }

    .banknote-imageactions {
        grid-area: colleft;
    }
    .banknote-header {
        margin-top: 1rem;
    }
}

.modal-title-login {
    font-size: 1.2rem !important;
}
.text-blur {
    filter: blur(10px);
    -webkit-filter: blur(10px);
}
