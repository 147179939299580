/*NAVLINE IS TOP LINE COMPONENTS INCLUDE AUTHENTIFICATION AND LANGUAGE*/

/*NAVLINE*/
.navline {
  @include default-container();
  display: flex;
  flex-direction: row;
  // margin-bottom: .5rem;
  color: #fff;


  &.right {
    justify-content: flex-end;
  }

  /*GROUP ELEMENTS*/
  .navline-group {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    &.center {
      flex-grow: 2;
      justify-content: center;
    }
  }

  /*TEXT*/
  .navline-text {
    align-self: center;
    padding: 0 .5rem;
  }

  /*ITEMS*/
  .navline-item
  {
    align-self: center;
    padding: 0 .5rem;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

/*SPECIFIC FOR MENU LANG*/
#dropdownMenuLang {
  height: 100%;
  padding-top: .75rem;
  padding-right: 1rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  margin-right: .1rem;
  @media (max-width: $mobile-breakpoint) {
    padding-right: .1rem;
  }

}

/*SPECIFIC FOR DROPDOWN MENU LANG*/
.dropdown-menu-sizelang {
  width: 70px;
  min-width: 70px;
  .dropdown-item {
    padding: .25rem;
    text-align: center;
  }
  .dropdown-header {
    padding: .25rem 1.5rem;
  }
}
