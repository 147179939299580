@import 
	'../../mixins',
	'../../includes',
	'../../variables';


.mm-sronly {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	clip-path: inset(50%) !important;
	white-space: nowrap !important;
	width: 1px !important;
	min-width: 1px !important;
	height: 1px !important;
	min-height: 1px !important;
	padding: 0 !important;
	overflow: hidden !important;
	position: absolute !important;
}