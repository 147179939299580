$lis-shadow: 0 0.5rem 1rem $navline !default;

#loader { opacity: 0; }
.loader-active { opacity: 1 !important; }
.result_separator { width: 100%; }


.modal-itembanknotelink:visited {
    background-color: red;

}


.banknote-gallery figure {
    margin: 0 0 1rem !important;
}

[data-pid] .inventory-hide-pid { display: none; }

.container-indexgrid .title-truncate { line-height: 1rem; height: 40px; width: 150px; overflow: hidden;  }

.container-indexlist .alphabet { font-size: 1rem; }
.container-indexlist .item-banknote-header { 
font-size: .9rem !important;
padding: 0 .2rem;

flex-direction: column;
align-items: flex-start; }
.item-banknote-owner { padding: .1rem .2rem; }
.item-banknote-header .quality .badge { font-size: .8rem; }


/*** 
.item-banknote 
> .item-banknote-image
> .item-banknote-header
> .item-banknote-title

*/

.item-banknote {
    margin: 0;
    color: #000;
    background-color: #fff;
    border: 2px solid $listing-area;
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    z-index: 9;

    &:hover {
        z-index: 10;
        cursor: pointer;
        background-color: $listing-active;
        //border: 2px solid $listing;
        box-shadow: $lis-shadow;
        text-decoration: none;
        color: #000;

        .item-banknote-image .zoom img {
            -webkit-transform: scale(2);
            transform: scale(2);
            filter: grayscale(0);
        }

        .item-banknote-footer-hover {
            display: block;
        }
    }

    &:visited:not(.archives):not(.inv) {
        border: 2px solid $listing;
        z-index: 10;
        cursor: pointer;
        background-color: $listing-active;
        box-shadow: $lis-shadow;
        text-decoration: none;
        color: #000;

        .item-banknote-image .zoom img {
            -webkit-transform: scale(2);
            transform: scale(2);
            filter: grayscale(0);
        }

        .item-banknote-header .listingid {
            background-color: $listing;
            color: #fff;
        }
    }

    &.archives:visited {
        border: 2px solid $archives !important;
        z-index: 10;
        cursor: pointer;
        // background-color: $listing-active;
        box-shadow: $lis-shadow;
        text-decoration: none;
        color: #000;

        .item-banknote-image .zoom img {
            -webkit-transform: scale(2);
            transform: scale(2);
            filter: grayscale(0);
        }
    }
    &.archives:hover {
        border-color: $archives !important;

        .item-banknote-image .zoom img {
            -webkit-transform: initial;
            transform: initial;
        }
    }

    &.inv:visited {
        border: 2px solid $inv !important;
        z-index: 10;
        cursor: pointer;
        // background-color: $listing-active;
        box-shadow: $lis-shadow;
        text-decoration: none;
        color: #000;

        .item-banknote-image .zoom img {
            -webkit-transform: scale(2);
            transform: scale(2);
            filter: grayscale(0);
        }
    }
    &.inv:hover {
        border-color: $inv !important;

        .item-banknote-image .zoom img {
            -webkit-transform: initial;
            transform: initial;
        //     -webkit-transform: scale(2);
        //     transform: scale(2);
        }
    }
}
.item-banknote-id {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.8rem;
    line-height: 0.7rem;
    padding: 0.3rem 0.3rem;
    color: darkgray;
    background-color: #fff;
}
.item-banknote-footer-hover {
    display: none;
    background-color: #fff;
    text-align: center;
    padding: 0.1rem;

    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    z-index: 11;

    .btn {
        padding: 0.1rem 0.5rem;
        font-size: 0.8rem;
        line-height: 1rem;
        border-radius: 50px;
    }
}

.item-banknote-partners img {
    filter: grayscale(80%);
}

// .item-banknote-partners {
//     background-color: $listing-itemdark;
// }

// Image
.item-banknote-image {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 200px;
    overflow: hidden;
    text-transform: uppercase;

    z-index: 12;

    &.topcorner {
        align-items: flex-start;
    }

    span {
        padding-left: 5px;
    }

    .zoom {
        background-color: $listing-area;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        // -webkit-transform: scale(1);
        // transform: scale(1);

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
}




.item-vso-close {
    position: absolute;
    top: 45%;
    left: 0;
    height: 2rem;
    background-color: $navline;
    color: #fff;
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 0.1rem;
}

.item-vso-sold {
    position: absolute;
    top: 45%;
    left: 0;
    height: 2rem;
    background-color: $archives;
    color: #fff;
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding-top: 0.1rem;
}

.item-banknote-adminbar {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    font-size: 0.7rem;
    z-index: 10;

    a {
        padding: 0rem 0.3rem;
        color: #000;
        background-color: $listing-area;
    }

    a:hover {
        color: #fff !important;
        background-color: #000;
    }
}

.item-banknote-owner {
    padding-top: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    color: darkgray;
    font-size: 0.7rem;
    line-height: 0.7rem;
}

.item-banknote-header,
.item-banknote-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-banknote-header {
    font-size: 0.8rem;
    position: relative;
    padding: 0.3rem 0.3rem 0rem 0.3rem;

    .listingid {
        position: absolute;
        left: 0;
        top: -0.9rem;
        font-size: 0.8rem;
        line-height: 0.7rem;
        padding: 0.3rem 0.3rem;
        color: darkgray;
        background-color: #fff;
        z-index: 12;
    }

    .itemid {
        font-size: 0.8rem;
        line-height: 0.7rem;
        padding: 0.3rem 0.3rem;
        color: darkgray;
        background-color: white;
        z-index: 12;
    }

    .vendor {
        padding-top: 0.3rem;
    }
    .owner {
        padding-top: 0.3rem;
        color: darkgray;
        font-size: 0.8rem;
        line-height: 0.7rem;
    }
    .rarityquality {
        display: flex;
        flex-grow: 2;
        justify-content: flex-end;
    }

    .vendor.vso {
        color: $listing;
    }

    .rarity,
    .vendor,
    .quality {
        width: 30%;
    }

    .alphabet > span {
        margin-right: 0.5rem;
    }
    .alphabetpick {
        display: none;
    }

    .rarity {
        text-align: center;
    }

    // .rr {
    //     height: 14px;
    //     color: $listing;
    //     fill: $listing;
    //     filter: grayscale(0);
    // }
    // .c {
    //     height: 10px;
    //     padding-left: 2px;
    // }
    .quality {
        .badge {
            font-size: 0.9rem;
        }

        text-align: right;
    }
}

.item-banknote-footer {
    font-size: 0.7rem;
    line-height: 1rem;
    color: $listing;
    padding: 0.3rem;

    &.archives {
        color: $archives;
    }

    .action .btn {
        padding: 0.1rem 0.5rem;
        line-height: 1rem;
        font-size: 0.8rem;
        border-radius: 50px;
    }

    .price {
        font-weight: bold;
        font-size: 0.8rem;
    }
}

.item-banknote-title {
    padding: 0 0.3rem;
    font-size: 0.9rem;

    .title-country {
        font-weight: bold;
    }
}

.adminbar {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    font-size: 0.7rem;

    a {
        padding: 0.2rem;
        color: #000;
    }

    a:hover {
        color: #fff !important;
        background-color: #000;
    }
}

/****
.container-indexgrid > .grid-item-banknote
.container-indexlist > .grid-item-banknote
*/

.container-indexgrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .grid-item-banknote {
        width: 25%;
        padding: 0.25rem;
        position: relative;

        @media (max-width: $tablette-breakpoint) {
            width: 50%;
            padding: 0.25rem;
        }

        @media (max-width: $mobile-breakpoint) {
            width: 100%;
            padding: 0;
        }
    }
    .item-banknote-id {
        bottom: 0;
        top: auto;
        z-index: 14;
    }

    .inv .alphabet {
	width: 150px;
    }

    .inv .title-banknote,
    .inv .item-banknote-id {
        display: none;
    }

    .inv .item-banknote-header .rarity,
    .inv .item-banknote-header .quality {
        width: initial;
    }
    .inv .item-banknote-header {
        padding-bottom: 0.3rem;
    }

    .inv .item-banknote-title {
        padding-left: 0rem;
    }
}

.container-indexlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // height: 600px;
    // width: 100%;

    .grid-item-banknote {
        width: 49%;
        position: relative;

        @media (max-width: $tablette-breakpoint) {
            width: 100%;
        }
    }

    .item-banknote {
        display: grid;

        grid-template-columns: 150px 1fr;
        grid-template-rows: 20px 60px;
        gap: 0px 0px;
        grid-template-areas:
            'a b'
            'a c';
    }
    .item-banknote-image {
        // width: 100px;
        height: 100%;
        grid-area: a;
        overflow: hidden;

        .zoom {
            width: 100%;
            img {
                height: auto;
                width: 100%;
            }
        }
    }

    .item-banknote-header {
        grid-area: c;
        padding: 0rem 0.3rem 0.3rem 0.3rem;
        .rarity,
        .vendor,
        .quality {
            width: auto;
        }
    }
    .item-banknote-title {
        grid-area: b;
        line-height: 1.1rem;
        padding-left: 0rem;
    }
}

.container-indexlist.active-pid {
    .grid-item-banknote {
        width: 33%;
        @media (max-width: $tablette-breakpoint) {
            width: 49%;
        }
        @media (max-width: $mobile-breakpoint) {
            width: 100%;
        }
    }

    .item-banknote {
        grid-template-columns: 70px 1fr;
        grid-template-rows: 20px 25px !important;
    }
    // .item-banknote-image {
    //     img {
    //         height: 45px !important;
    //     }
    // }

    .inv .title,
    .inv .item-banknote-id {
        display: none;
    }
    .alphabet > span {
        margin-right: 0.5rem;
        display: inline-block;
    }
    .alphabetdate {
        min-width: 100px;
    }
    .alphabetserial {
        min-width: 50px;
    }
    .alphabetpick {
        width: 25px;
    }
    .item-banknote-title {
        font-size: 0.7rem;
        line-height: 0.7rem;
    }
    .item-banknote-header .rarityquality {
        flex-grow: 1;
        .rarity {
            min-width: 50px;
        }
        .quality {
            text-align: left;
            min-width: 40px;
        }
    }

    .rr {
        height: 9px;
    }

    .item-banknote-owner {
        text-align: right;
    }

    .item-banknote-footer-hover {
        padding: 0rem;
        font-size: 0.7rem;
        top: 0rem;
        left: 75px;
    }
}
