
.last_listing {
  padding: 3rem .5rem;


  background-color: $listing-area;
  border-color: $listing-area;
  border-style: solid;
  border-radius: 5px;
}
