.mm-menu {
    @if ($mm_IE11Fallbacks) {
        top: $mm_offsetTop;
        right: $mm_offsetRight;
        bottom: $mm_offsetBottom;
        left: $mm_offsetLeft;

        background: $mm_backgroundColor;
        border-color: $mm_borderColor;
        color: $mm_textColor;
        line-height: $mm_lineHeight;
    }

    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    position: absolute;
    top: var(--mm-offset-top);
    right: var(--mm-offset-right);
    bottom: var(--mm-offset-bottom);
    left: var(--mm-offset-left);
    z-index: 0;

    overscroll-behavior: auto;

    background: var(--mm-color-background);
    border-color: var(--mm-color-border);
    color: var(--mm-color-text);
    line-height: var(--mm-line-height);

    -webkit-tap-highlight-color: var(--mm-color-background-emphasis);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a,
    a:link,
    a:active,
    a:visited,
    a:hover {
        text-decoration: none;
        color: inherit;
    }
}
