/*UI COMPONENTS FOR CATALOG*/

/*TOOLS (MISC)

       AREA
       EDITO
            EDITO COLLAPSE
       EDITO SPACE
       HOME GRID*/

/*AREA*/

.badge-catalog {
    font-weight: normal;
    background-color: #fdeee3;
}

/*EDITO COLLAPSE*/
.openclosecollapse {
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    text-align: center;
}

.openclosecollapse svg {
    height: 26px;
    color: #6f1753;
    fill: #6f1753;
}

.openclosecollapse[aria-expanded='true'] {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.edito-home {
    margin-top: 0;
}

.edito-menu a {
    color: #6f1753;
}

.edito-menu a:hover {
    color: #df382c;
}

.catalog-edito-text {
    //   padding-top: 2rem;
    //   padding-bottom: 2rem;
    padding: 4rem;
    padding-left: 4rem;
    text-align: left;
    font-size: 1rem;
    line-height: 2rem;
    max-width: 100%;
    margin: auto;

    @media (max-width: $mobile-breakpoint) {
        max-width: 100%;
        padding: 1.5rem;
        font-size: 1rem;
    }
}

/*EDITO*/
.catalog-cms-edito {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0;
    padding-top: 1px;

    &.bg-white {
        background-color: #fff;
    }

    #catalog-cms-edito-text {
        background-color: #fff;

    }

    .edito {
        @include default-container();
        position: relative;
        z-index: 12;
        width: 100%;

        // margin-top: 3rem;
        // margin-left: 2.5rem;
        .text {
            padding: 1rem 0;
            text-align: left;
        }

        .text-content {
            font-size: 1.1rem;
        }

        .text-title {
            font-size: 1.4rem;
        }

        .text.collapse {
            position: relative;
            display: block;
            height: 250px;
            overflow: hidden;

            &::after {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 80%;
                content: '';
                background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0) 30%,
                    #fff 100%
                );
                background-repeat: repeat-x;
            }
        }

        .text.show {
            height: auto;

            &::after {
                height: 0;
            }
        }
    }
}

.edito-visuel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
}

.edito-visuel svg {
    width: 100px;
}

.edito .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    /*V307 font-size: 1.3rem;*/
}

.edito .text h1 {
    color: #6f1753;
}

/*TOOLS*/
.badge-sm {
    margin-right: 0.5rem;
    margin-left: 1rem;
    font-size: 1.5rem;
}

.s-fullwidth {
    width: 100%;
    min-width: 100%;
}

/*EDITO SPACE*/
.edito-space {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
    margin-top: 3rem;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.4rem;
    color: #fff;
    text-decoration: none;
    background-color: #6f1753;
    border-radius: 5px;
}

.edito-space:hover {
    color: #fff;
    background-color: #df382c;
}

.edito-space svg {
    height: 68px;
}

/*CATALOG CONTAINER*/
// .catalog-cards-container {
//     margin-left: 2.5rem;
// }

/*RESPONSIVE*/
@media screen and (max-width: $mobile-breakpoint) {
    .edito-visuel svg {
        width: 60px;
    }

    .edito {
        padding-bottom: 0;
        margin-top: 2rem;
        margin-right: 0;
        margin-left: 0;
    }

    .edito .text {
        padding-top: 1rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .edito .text h1 {
        font-size: 1.5rem;
    }

    .edito-menu {
        display: none;
    }

    .catalog-cms-edito {
        margin-right: 0;
        margin-left: 0;

        #catalog-cms-edito-text {
            padding-left: 1rem;
            margin-left: 0;
        }
    }

    .badge-sm {
        font-size: 1.1rem;
    }

    .catalog-cards-container {
        margin-left: 0;
    }

    .catalog-card {
        margin: 0;
        margin-bottom: 0.5rem;
    }
}

.articlelist-margin {
    min-height: 50vh;
    padding-right: 1rem;
    margin-top: 2rem;
    margin-right: 1rem;
    margin-bottom: 4rem;
    font-size: 1rem;
    border-right: 1px solid $cms;

    @media (max-width: $mobile-breakpoint) {
        padding-right: 0;
        margin-right: 0;
        border-right: initial;
    }
}

.banknote-titlebar-drop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.banknote-titlebar-drop .btn-cta {
    padding: 0.5rem 1.5rem;
    font-size: 1.2rem;
    border-radius: 50px;
    xfont-weight: bold;
}

.banknote-titlebar-drop h1 {
    display: inline;
    padding: 1rem;
    margin: 0;
    font-size: 1.8rem;

    font-weight: bold;
}

.catalog-gallery {
    display: flex;
    flex-direction: row;

    figcaption {
        display: none;
    }

    figure:first-child {
        width: 50%;
        margin-right: 0.2rem;
    }

    figure:last-child {
        width: 50%;
        margin-left: 0.2rem;
    }
}

@media screen and (max-width: $tablette-breakpoint) {
    .banknote-titlebar-drop h1 {
        padding: 1rem 0.5rem 0.5rem;
        font-size: 1.5rem;
    }

    .banknote-titlebar-drop {
        flex-direction: column;
    }

    .banknote-titlebar-drop .btn-cta {
        padding: 0.3rem 1rem;
        margin-bottom: 1rem;
        font-size: 1.1rem;
    }

    .catalog-gallery {
        flex-direction: column;
    }

    .catalog-gallery figure:first-child {
        width: 100%;
        margin-right: 0;
    }

    .catalog-gallery figure:last-child {
        width: 100%;
        margin-left: 0;
    }
}

/*CATALOG*/

/*PID LIST*/

.catalog-iidx {
    position: relative;
    z-index: 12;
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-left: 1rem;
    margin-top: 1rem;
    margin-right: 2rem;
    margin-left: 2rem;
}

.iid-header {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-left: 1rem;

    @media screen and (max-width: $mobile-breakpoint) {
        margin-left: 0.5rem;
    }
}

.img-pidlist {
    height: 100%;
}

.iid-picto {
    width: 50px;
}

.iid-title {
    position: relative;
    z-index: 12;
    padding-left: 1rem;
    font-size: 1.6rem;
    color: $catalog;
}

.img-rectoverso {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out 0.5s ease;

    transform: scale(1.2);
}

.ximg-rectoverso:hover {
    transition: transform 0.5s ease;
}

/*RESPONSIVE*/
@media screen and (max-width: $mobile-breakpoint) {
    .catalog-iid {
        padding-top: 0.7rem;
        padding-left: 1rem;
        margin-top: 2rem;
        margin-right: 0;
        margin-left: 0;
        background-color: #fff;
    }

    .iid-visuel {
        top: -20px;
    }

    .iid-visuel svg {
        width: 40px;
    }

    .iid-title h2 {
        padding: 0.5rem;
        padding-left: 0.5rem;
        font-size: 1.2rem;
    }

    .iid-title {
        margin-left: 0;
        color: #6f1753;
    }
}

/**/

.catalog_hero {
    background-color: $light3;
}

.catalog-list-and-sidebar {
    display: flex;
    background-color: $white;
}

.catalog-list-wrapper {
    width: 100%;
}

.catalog-list-sidebar {
    display: none;
}

.catalog-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // padding: 0.5rem;
}

.catalog-card-selected,
.catalog-card {
    width: 30%;
    margin: 0.5em;
}

@media (min-width: $mobile-breakpoint+1) and (max-width: $tablette-breakpoint) {
    .catalog-card-selected,
    .catalog-card {
        width: 48%;
        margin: 0.5rem;
    }

    .catalog-list-sidebar {
        display: none;
    }

    .catalog-list-wrapper {
        width: 100%;
    }
}

@media (max-width: $mobile-breakpoint) {
    .catalog-card-selected,
    .catalog-card {
        width: 100%;
        margin: 0;
    }

    .catalog-list-sidebar {
        display: none;
    }

    .catalog-list-wrapper {
        width: 100%;
    }
}

.noused-img-rectoverso {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

/*.catalog-card-selected .catalog-card-content, .catalog-card-selected .catalog-listingcount {
	background-color: #2ab7a9;
	color: #000;
  }*/

.catalog-card-content {
    display: flex;
    align-items: center; //flex-start;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    overflow: hidden;
    font-size: 1em;

    line-height: 1.1em;
    color: $white;
    background-color: $catalog;

    .catalog-card-content--sep {
        width: 2px;
        height: 100px;
        border-left: 1px solid $white;
    }

    .catalog-card-content--pick {
        height: 100%;
        padding: 0.5rem;
        padding-top: 1rem;
        text-align: right;
        border-left: 1px solid $white;

        // padding-right: .5em;
        // padding-left: .5em;
    }

    .catalog-card-content--name {
        padding: 0.5rem;
        font-weight: bold;
        // padding-left: .5em;
        // padding-right: .5em;
    }
}

.catalog-card:hover .catalog-card-content,
.catalog-card:hover .catalog-listingcount {
    color: $white;
    cursor: pointer;
    background-color: $catalog-hover;
}

.catalog-help-title {
    padding-top: 3rem;
    padding-left: 0.5rem;
    font-size: 2rem;
}

.catalog-label {
    width: 100%;
    margin: 1rem;
    font-size: 1.3em;
    font-weight: bold;
    color: $catalog;
    border-bottom: 2px solid $catalog;
}

.catalog-label-issuing {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
}

.catalog-card-image {
    position: relative;
    width: auto;
    height: 250px;
    max-height: 250px;
    overflow: hidden;
    border: 2px solid $dark2;

    a {
        display: flex;
        align-content: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        overflow: hidden;

        .img-rectoverso {
            height: 300px;
            vertical-align: middle;
            box-shadow: 2px 2px 15px $image-border;
            align: center;
        }
    }
}

.catalog-listingcount {
    position: absolute;
    bottom: 20px;
    display: inline-block;
    padding: 0.5em;
    font-weight: bold;
    color: $white;
    background-color: $catalog;
    border-radius: 3px;
}

.catalog-label-w240 {
    max-width: 240px;
    margin: 0.5rem;
    font-weight: bold;
    border-bottom: 2px solid $black;
}

.catalog-label-light {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1rem;
}

.catalog-label-light:hover {
    opacity: 0.7;
}

.catalog-label-light i {
    padding-right: 1rem;
    font-size: 4rem;
}

.catalog-label-light strong {
    font-weight: bold;
    color: $dark2;
}

.catalog-selected,
.catalog-choice {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
}

.catalog-selected a,
.catalog-choice a {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    min-height: 50px;
    padding: 0.2rem;
    margin: 0.2rem;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    border-radius: 5%;
}

.catalog-choice a:hover {
    background-color: $light2;
}

.catalog-choice a {
    color: #000;
    background-color: $light3;
}

.catalog-selected a {
    color: $white;
    background-color: $dark2;
}

.catalog-country-size {
    width: 150px;
}

.search_banknote-title a {
    font-size: 1.6em;
    color: $white;
}

.search_banknote-title a:hover {
    text-decoration: none;
}

.chartCatalog {
    width: 100%;
    padding: 0.4em;
    background-color: $white;
    border: 2px solid $catalog;
    border-radius: 5px;
}

#chartContainer1 {
    width: 100%;
    height: 370px;
}

.catalog-choice-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}
