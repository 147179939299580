.footer-logo { height: 40px; }


.post-footer {
  padding: 2rem;
  background-color: #fff;
  xcolor: #6f1753;
}

.post-footer a {
  flex-direction: row;


}

.post-footer-bar {
  display: block;
  color: #fff;
}

.post-footer-bar p { max-width: 900px; }

.footer {
  width: 100%;
  padding-top: 2rem;
  color: #fff;
  xbackground-color: #6f1753;
}

.footer-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;


}

.footer-item {
  padding: .5rem;


  /*font-size: 1.2rem;*/
  color: #fff !important;
}

.footer-item:hover {
  color: #fff !important;
}

.footer-item.unbold {
  font-weight: normal;
}

.pre-footer {
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: .9rem;
  color: #fff;
  text-align: center;
  xbackground-color: #4e1135;
}

.pre-footer:hover {
  color: #fff;
  text-decoration: none;
  xbackground-color: #8d1e5f;
}

@media screen and (max-width: $tablette-breakpoint) {
  .post-footer-bar p { max-width: 100%; }
}

@media screen and (max-width: $mobile-breakpoint) {

  .footer-bar {
    flex-direction: column;
  }


}
