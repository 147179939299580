@import '../../mixins', '../../includes', '../../variables';

%mm_navbars {
}

@if ($mm_include_navbars_top) {
    .mm-navbars_top {
        flex-shrink: 0;

        .mm-navbar:not(:last-child) {
            border-bottom: none;
        }
    }
}

@if ($mm_include_navbars_bottom) {
    .mm-navbars_bottom {
        flex-shrink: 0;

        .mm-navbar {
            border-bottom: none;

            &:first-child {
                @if ($mm_IE11Fallbacks) {
                    border-top: 1px solid $mm_borderColor;
                }

                border-top: 1px solid var(--mm-color-border);
            }
        }
    }
}

@import 'navbar.searchfield', 'navbar.breadcrumbs', 'navbar.tabs';
