// OSCAR CHECKOUT UNIQUE STYLES
// ----------------------------

// Set max width of all quantity fields
input[type="text"][id*="quantity"],
input[type="number"][id*="quantity"] {
  max-width: 55px;
}

.checkout-quantity .input-group {
  max-width: 160px;

  input[type="number"][id*="quantity"] {
    max-width: 65px;
  }

  .input-group-btn {
    min-width: 95px;
  }
}

// Quantity on the basket
.checkout-quantity {
  input {
    margin-bottom: 0;
  }
}

// Basket items like table headers
.basket-title {
  clear: both;
  border-bottom: 1px solid $gray-200;
  padding: 0 10px;
}

// Basket items
.basket-items {
  border-bottom: 1px solid $gray-400;
  padding: 10px;
  margin-bottom: 20px;

  &:nth-child(even) {
    background: $gray-200;
  }

  .price_color {
    @extend .h4;
    font-size: $font-size-base;
    // color: $success;
    // font-weight: bold;

    .price-title {
      color: $black;
      font-weight: normal;
    }
  }

  .basket-line-actions {
    font-size: $font-size-small;
  }
}

// Descriptions next to shipping prices - basket totals table
#basket_totals {
  th small {
    font-weight: normal;
  }
}

// Table background for totals
.total {
  background-color: $mark-bg !important;
}

// Checkout navigation - uses navbar
.nav-checkout {
  text-align: center;

  &.navbar .nav {
    margin: 0;
  }

  .active {
    .navbar-text {
      color: $white;
    }
  }

  .nav-item {
    margin-left: $navbar-padding-x;
    margin-right: $navbar-padding-x;
  }
}

.shipping-payment {
  .card {
    margin-bottom: $base-margin-bottom;

    h3 a {
      font-size: 1rem;
      font-weight: $font-weight-normal;
    }

    h3 {
      font-size: 1.2rem;
    }
  }
}
.basket-title h3 {
  font-size: 1.1rem;
}
.basket-title h3 a {
  font-size: 1rem;
}
.checkout-layout--title {
  padding-top: 2rem;
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
}

@media (max-width: 600px) {
  .checkout-layout--title {
    padding-top: 1rem;
  }
}
