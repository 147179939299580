@import '../../mixins', '../../includes', '../../variables';

//	Justified listviews
@if ($mm_include_listview_justify) {
    .mm-menu_listview-justify .mm-panels > .mm-panel {
        display: flex;
        flex-direction: column;

        &:after {
            content: none;
            display: none;
        }

        .mm-listview {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }

        .mm-listitem {
            flex: 1 0 auto;
            min-height: $mm_btnSize; /* for Safari :( */
        }

        .mm-listitem__text {
            box-sizing: border-box;
            flex: 1 0 auto;
            display: flex;
            align-items: center;
        }
    }
}

//	Inset lists
@if ($mm_include_listview_inset) {
    .mm-listview_inset {
        list-style: inside disc;
        width: 100%;
        padding: 0
            ($mm_padding + $mm_listitemIndent)
            ($mm_padding * 1.5)
            ($mm_padding + $mm_listitemIndent);
        margin: 0;

        .mm-listitem {
            padding: ($mm_padding * 0.5) 0;
        }
    }
}
