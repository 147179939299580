.account-border { border: 3px solid $profile; border-radius: 10px; padding-left: 1rem; padding-right:1rem; padding-top: .5rem; padding-bottom: 1rem; margin-bottom: 1rem;}

.account {
  margin-top: 1rem;
  margin-bottom: 2rem;

  .h2 { font-size: 1.1rem; color: $cms; }

  .account-card {
    max-width: 400px;
    font-size: 1rem;
    line-height: 1.3rem;
    .h5 { font-size: 1.15em; }
    .card-title { margin-bottom: .3rem; }
  }

  .account-user,
  .account-preference,
   {
    max-width: 600px;
    padding-left: 2rem;

    @media (max-width: 600px) {
      max-width: 100%;
      margin-left: .5rem;
    }
  }
  .account-save
    {

    padding-top: .5rem;
    padding-left: .5rem;
    margin-top: .5rem;


  }

  .account-topaction
    {

    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;

  }
  .account-card {
    max-width: 600px;
    padding: 1rem;
    border: 1px solid $catalog;
    border-radius: 5px;

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

}
