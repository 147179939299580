/*MMENU LIGHT*/
:root {
  // --mm-spn-item-height: 32px;
  // --mm-ocd-max-width: 500px;
  --mm-color-background: #fff;
  --mm-color-text: #000;
}

// .mm-spn ul.mm-spn--open { top: 0 !important; }
.mm-nobefore::before {
  width: 0 !important;
  height: 0 !important;
}

.mobile-menu {
  .avatar-name {
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-right: 13px;

    font-size: 1.1rem;
    color: #fff;
    vertical-align: middle;
    background-color: $catalog;
    border-radius: 50%;
  }
  .avatar-text {
    width: 100%;
    line-height: 1.2rem;
  }
  .width100 {
    width: 100%;
  }
  .picto {
    height: 16px;
  }
  .separator {
    padding: 0.3rem;
    padding-left: 20px;
    margin-top: 0.5rem;
    font-size: 0.7rem;
    color: darkgray;
  }

  .menu-norightcarret::before {
    opacity: 0 !important;
  }
  .mmenu-topline {
    border-top: 2px solid;
  }

  .menu-small-bottom {
    padding-bottom: 0 !important;
    border: 1px solid red;
  }

  .menu-small {
    padding: 5px !important;
  }
}

.mobile-menu-country .mm-navbar_sticky {
  display: none;
}
.mobile-menu-country.archives .mm-navbars_top .mm-navbar {
  background-color: $archives;
}
.mobile-menu-country.cat .mm-navbars_top .mm-navbar {
  background-color: $catalog;
}
.mobile-menu-country.lis .mm-navbars_top .mm-navbar {
  background-color: $listing;
}
// .mobile-menu-country .mm-btn_close.mm-btn:before,
// .mobile-menu-country .mm-btn_close.mm-btn:after {
//   color: #fff;
// }
.mm-searchfield input,
.mm-searchfield input:hover,
.mm-searchfield input:focus {
  background-color: #fff !important;
}

.mobile-menu-country .mm-navbars_top .mm-btn:before,
.mobile-menu-country .mm-navbars_top .mm-btn:after {
  border-color: #fff !important;
}
